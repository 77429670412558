import {
  AllGameDataBySeasonIdQuery,
  AllGameDataVersionsQuery,
  GameData,
  GameDataVersion
} from "@liveops-portal/lib"
import { getError } from "@/helpers/errors"
import notify from "@/helpers/notify"
import { api } from "@/store/api"

export const gameDataApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGameDataVersions: builder.query<string[], AllGameDataVersionsQuery>({
      query: ({ gameId }) => ({
        url: "game-data/versions",
        params: { gameId }
      }),
      onQueryStarted({ gameId }, { queryFulfilled }) {
        queryFulfilled.catch((err) =>
          notify.error(
            `Could not get game data versions for game ${gameId}. ${getError(err)}`
          )
        )
      },
      transformResponse: (gameDataVersions: GameDataVersion[]) => {
        return gameDataVersions
          ?.filter(({ state }) => state === "READY_FOR_USE")
          .map(({ dataVersion }) => dataVersion.toString())
      }
    }),
    getGameDataBySeasonId: builder.query<
      Record<string, GameData[]>,
      AllGameDataBySeasonIdQuery
    >({
      query: ({ gameId, seasonId, dataTypes }) => ({
        url: `game-data`,
        params: { gameId, seasonId, dataTypes }
      }),
      onQueryStarted({ seasonId }, { queryFulfilled }) {
        queryFulfilled.catch((err) =>
          notify.error(
            `Could not get game data for season ${seasonId}. ${getError(err)}`
          )
        )
      }
    })
  }),
  overrideExisting: false
})

export const { useGetGameDataVersionsQuery, useGetGameDataBySeasonIdQuery } =
  gameDataApi
