import { FormControl, Option, OptionProps, Select, SelectProps } from "@mui/joy"
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  useFormContext
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormLabel } from "@/components/form-label/form-label"

interface Props<T extends FieldValues> extends SelectProps<string, false> {
  name: Path<T>
  label?: string
  rules?: RegisterOptions
  options: OptionProps[]
  hint?: string
}

/**
 * Renders a Joy UI Select bound to React Hook Form.
 * @param name
 * @param label
 * @param rules
 * @param options
 * @param hint
 * @param defaultValue
 * @param sx
 */
export const FormSelect = <T extends FieldValues>({
  name,
  label,
  rules,
  options,
  hint,
  defaultValue,
  sx,
  ...props
}: Props<T>) => {
  const { control } = useFormContext<T>()
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      {...(defaultValue != null && {
        defaultValue: defaultValue as PathValue<T, Path<T>>
      })}
      render={({ field: { onChange, value } }) => (
        <FormControl sx={sx}>
          {label && (
            <FormLabel required={!!rules?.required} hint={hint}>
              {label}
            </FormLabel>
          )}
          <Select
            {...props}
            size="sm"
            {...(defaultValue == null && {
              defaultValue: "placeholder"
            })}
            onChange={(_, newValue) => {
              onChange(newValue)
            }}
            value={value || "placeholder"}
            aria-label={label}
          >
            <>
              <Option disabled value="placeholder">
                {t(`_forms.placeholders.select`)}
              </Option>
              {options.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </>
          </Select>
        </FormControl>
      )}
    />
  )
}
