import {
  AutocompleteOption,
  Autocomplete,
  createFilterOptions,
  ListItemContent,
  Typography,
  Chip
} from "@mui/joy"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import { DEFAULT_GAMEID } from "@/constants/context"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useGetAllGamesQuery } from "@/store/api/game"
import {
  selectContext,
  selectEnv,
  selectGame,
  setGameId
} from "@/store/slices/context"

export const GameSelector: React.FC = () => {
  const dispatch = useAppDispatch()
  const env = useAppSelector(selectEnv)
  const context = useAppSelector(selectContext)
  const gameId = useAppSelector(selectGame)
  const gameInit = useRef(false)
  const [searchParams] = useSearchParams()
  const gameParam = useMemo(() => searchParams.get("gameId"), [searchParams])

  const { data: games, isFetching } = useGetAllGamesQuery(
    { env },
    { skip: !env }
  )

  const initializeGame = useCallback(() => {
    dispatch(setGameId(gameParam != null ? gameParam : DEFAULT_GAMEID))
  }, [dispatch, gameParam])

  useEffect(() => {
    if (!gameInit.current) {
      initializeGame()
      gameInit.current = true
    }
  }, [initializeGame])

  const value = useMemo(
    () => games?.find((game) => game.gameId === gameId) || null,
    [gameId, games]
  )

  return (
    games && (
      <Autocomplete
        autoComplete
        aria-label="Game Selector"
        id="game-selector"
        disabled={isFetching}
        value={value}
        options={games}
        filterOptions={createFilterOptions({
          stringify: (option) => `${option.name} ${option.gameId}`
        })}
        onChange={(_, _value) =>
          _value != null && dispatch(setGameId(_value.gameId))
        }
        sx={{
          position: "absolute",
          inset: 0,
          opacity: Number(context.includes("game")),
          width: 260,
          pointerEvents: context.includes("game") ? "all" : "none"
        }}
        startDecorator={<Chip color="game">Game</Chip>}
        isOptionEqualToValue={(option, value) => option.gameId === value.gameId}
        getOptionLabel={(option) => option.name}
        slotProps={{
          clearIndicator: { sx: { display: "none" } }
        }}
        renderOption={(props, option) => (
          <AutocompleteOption {...props} key={option.gameId}>
            <ListItemContent>
              <Typography level="body-sm" textColor="text.primary">
                {option.name}
              </Typography>
              <Typography level="body-xs" fontWeight="400" noWrap>
                {option.gameId}
              </Typography>
            </ListItemContent>
          </AutocompleteOption>
        )}
      />
    )
  )
}
