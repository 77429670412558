import {
  Operation,
  FeatureSwitchValueTypes,
  BooleanFeatureSwitch
} from "@liveops-portal/lib"
import { Grid, Select, Typography, Option, Button, Stack } from "@mui/joy"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { CardItem, CardItemProps } from "@/components/card-item/card-item"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  useDeleteOverrideValueMutation,
  useSetOverrideValueMutation
} from "@/store/api/feature-switch"
import { selectActiveOperation, setActiveOperation } from "@/store/slices/audit"
import { selectSpace } from "@/store/slices/context"

interface Props extends BooleanFeatureSwitch<boolean> {}

export const BooleanFeatureSwitchCard: React.FC<Props> = ({
  switchName,
  switchType,
  switchValue,
  defaultValue,
  overrideValue
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const spaceId = useAppSelector(selectSpace)
  const activeOperation = useAppSelector(selectActiveOperation)

  const setOperation: Operation = [switchName, "set"]
  const deleteOperation: Operation = [switchName, "delete"]
  const isSetOperationActive = isOperationActive(activeOperation, setOperation)
  const isDeleteOperationActive = isOperationActive(
    activeOperation,
    deleteOperation
  )

  const [overrideSelectValue, setOverrideSelectValue] = useState<
    boolean | null
  >(overrideValue)

  const [setOverrideValue, setOverrideValueMeta] = useSetOverrideValueMutation()
  const [deleteOverrideValue, deleteOverrideValueMeta] =
    useDeleteOverrideValueMutation()

  const isLoadingSet = isSetOperationActive || setOverrideValueMeta.isLoading
  const isLoadingDelete =
    isDeleteOperationActive || deleteOverrideValueMeta.isLoading

  const onSubmitHandler = () => {
    if (isSetOperationActive && overrideSelectValue !== null) {
      setOverrideValue({
        spaceId,
        switchName,
        switchValue: overrideSelectValue
      })
    }

    if (isDeleteOperationActive) {
      deleteOverrideValue({
        spaceId,
        switchName
      })
    }
  }

  const renderChipsProp = () => {
    const chips: CardItemProps["chips"] = [{ content: switchType }]

    if (overrideValue !== null) {
      chips.push({
        content: t(FeatureSwitchValueTypes.OVERRIDDEN),
        color: "danger"
      })
    }

    return chips
  }

  const isLoadingActions = isLoadingSet || isLoadingDelete

  return (
    <Grid md={6}>
      <OperationReason
        onSubmit={onSubmitHandler}
        open={isLoadingActions}
        autofocus
        closeable
      />
      <CardItem
        data-testid={`${switchName}-card`}
        chips={renderChipsProp()}
        title={{ content: switchName }}
        active={isLoadingActions}
        subtitles={[
          {
            label: t("_featureSwitches.defaultValue"),
            content: (
              <Typography color={defaultValue ? "success" : "danger"}>
                {defaultValue.toString()}
              </Typography>
            )
          },
          {
            label: t("_featureSwitches.currentValue"),
            content: (
              <Typography color={switchValue ? "success" : "danger"}>
                {switchValue.toString()}
              </Typography>
            )
          }
        ]}
        sections={[
          {
            title: t("_featureSwitches.valueOverride"),
            content: (
              <Stack mt={1} gap={1} direction="row">
                <Select
                  disabled={isLoadingActions}
                  slotProps={{
                    listbox: {
                      variant: "outlined",
                      color: "neutral",
                      sx: { p: 0 }
                    }
                  }}
                  onChange={(_, value) => {
                    dispatch(setActiveOperation(setOperation))
                    setOverrideSelectValue(value)
                  }}
                  aria-label="Override"
                  name="overrideValue"
                  value={isLoadingSet ? overrideSelectValue : overrideValue}
                  placeholder={t("_featureSwitches.overrideDefaultValue")}
                >
                  <Option value={true} key="true">
                    true
                  </Option>
                  <Option value={false} key="false">
                    false
                  </Option>
                </Select>
                {overrideValue !== null && (
                  <Button
                    disabled={isLoadingActions}
                    color="danger"
                    size="sm"
                    onClick={() => {
                      dispatch(setActiveOperation(deleteOperation))
                      setOverrideSelectValue(null)
                    }}
                  >
                    Reset to default
                  </Button>
                )}
              </Stack>
            )
          }
        ]}
      />
    </Grid>
  )
}
