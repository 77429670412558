import { AllGameServerBuildsQuery, GameServerBuild } from "@liveops-portal/lib"
import { getError } from "@/helpers/errors"
import notify from "@/helpers/notify"
import { api } from "@/store/api"

export const gameServerBuildApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGameServerBuilds: builder.query<
      GameServerBuild[],
      AllGameServerBuildsQuery
    >({
      query: ({ gameId }) => ({
        url: "game-server-builds",
        params: { gameId }
      }),
      onQueryStarted({ gameId }, { queryFulfilled }) {
        queryFulfilled.catch((err) =>
          notify.error(
            `Could not get builds for game ${gameId}. ${getError(err)}`
          )
        )
      }
    })
  }),
  overrideExisting: false
})

export const { useGetGameServerBuildsQuery } = gameServerBuildApi
