import { OtherFeatureSwitch } from "@liveops-portal/lib"
import { Grid, Typography } from "@mui/joy"
import { WarningTriangle } from "iconoir-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { CardItem } from "@/components/card-item/card-item"

interface Props extends Pick<OtherFeatureSwitch, "switchName" | "switchType"> {}

export const OtherFeatureSwitchCard: React.FC<Props> = ({
  switchName,
  switchType
}) => {
  const { t } = useTranslation()

  return (
    <Grid md={6}>
      <CardItem
        chips={[{ content: switchType }]}
        title={{ content: switchName }}
        subtitles={[
          {
            content: (
              <Typography
                level="body-xs"
                color="warning"
                startDecorator={<WarningTriangle />}
              >
                {t("_featureSwitches.typeCannotBeShown")}
              </Typography>
            )
          }
        ]}
      />
    </Grid>
  )
}
