import { Healthcheck } from "@liveops-portal/lib"
import { Stack, Typography } from "@mui/joy"
import React from "react"
import { HealthcheckCard } from "@/components/healthcheck-card/healthcheck-card"
import { HealthcheckCreate } from "@/components/healthcheck-create/healthcheck-create"
import { HealthcheckTrigger } from "@/components/healthcheck-trigger/healthcheck-trigger"
import { useAppSelector } from "@/hooks/store"
import { usePageActions } from "@/hooks/usePageActions"
import { useGetHealthchecksQuery } from "@/store/api/healthcheck"
import { selectEnv } from "@/store/slices/context"

/**
 * Component representing the Healthcheck page.
 */
export const HealthchecksPage: React.FC = () => {
  const env = useAppSelector(selectEnv)

  const { data: healthchecks, isFetching } = useGetHealthchecksQuery(
    { env },
    { skip: !env }
  )

  usePageActions([
    <HealthcheckTrigger key="healthcheck-trigger" />,
    <HealthcheckCreate key="healthcheck-create" />
  ])

  return !env ? (
    <Typography>
      Select an environment to display services healthchecks.
    </Typography>
  ) : (
    <Stack gap={2}>
      {isFetching ? (
        <Typography>Loading...</Typography>
      ) : healthchecks?.length ? (
        <Stack gap={2}>
          {healthchecks.map((healthcheck: Healthcheck) => (
            <HealthcheckCard key={healthcheck.serviceName} {...healthcheck} />
          ))}
        </Stack>
      ) : (
        <Typography>
          No healthchecks available for selected environment.
        </Typography>
      )}
    </Stack>
  )
}
