import {
  ToolboxRequest,
  isValidJson,
  formatJsonToString,
  Method,
  ServiceName
} from "@liveops-portal/lib"
import { Divider, Grid, Stack, Typography } from "@mui/joy"
import { MessageAlert } from "iconoir-react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormSelect } from "@/components/form-select/form-select"
import { ToolboxHistory } from "@/components/toolbox-history/toolbox-history"
import {
  getFromLocalStorageArray,
  pushToLocalStorageArray
} from "@/helpers/local-storage"
import { useAppSelector } from "@/hooks/store"
import { useSendRequestMutation } from "@/store/api/toolbox"
import { selectSpace } from "@/store/slices/context"

interface FormData extends ToolboxRequest {}

export const ToolboxPage: React.FC = () => {
  const spaceId = useAppSelector(selectSpace)
  const methods = useForm<FormData>({ mode: "all" })
  const [sendRequest, { data: response, isLoading }] = useSendRequestMutation()
  const [queryParams, setQueryParams] = useState<string>("{}")
  const [body, setBody] = useState<string>("{}")

  const onSubmit = (formData: FormData) => {
    const parsedQueryParams = JSON.parse(queryParams)
    const parsedBody = JSON.parse(body)

    const completeRequest = {
      ...formData,
      endpoint: formData.endpoint.trim(),
      queryParams: parsedQueryParams,
      body: parsedBody,
      spaceId
    }

    sendRequest(completeRequest)
    pushToLocalStorageArray("toolboxHistory", completeRequest)
  }

  const onToolboxHistoryClick = (index: number) => {
    const request = getFromLocalStorageArray(
      "toolboxHistory",
      index
    ) as ToolboxRequest

    methods.reset(request)

    setQueryParams(formatJsonToString(request.queryParams))
    setBody(formatJsonToString(request.body))
  }

  return (
    <Grid container spacing={2} flexGrow={1}>
      <Grid xs={8}>
        <Stack gap={2} height="100%">
          {!spaceId ? (
            <Typography startDecorator={<MessageAlert />} color="warning">
              Please select a space to use the Toolbox.
            </Typography>
          ) : null}
          <Form
            gap={2}
            methods={methods}
            disabled={!spaceId}
            loading={isLoading}
            onSubmit={onSubmit}
            submitLabel="Send request"
          >
            <FormSelect
              name="serviceName"
              label="Service Name"
              defaultValue={ServiceName.UNIMATRIX_XYQ_SERVICE}
              options={Object.values(ServiceName).map((serviceName) => ({
                value: serviceName,
                label: serviceName
              }))}
            />
            <Stack direction="row" gap={2}>
              <FormSelect
                name="method"
                label="Method"
                defaultValue={Method.GET}
                options={Object.values(Method).map((method) => ({
                  value: method,
                  label: method
                }))}
                sx={{ flexBasis: "5%" }}
              />
              <FormInput
                type="text"
                name="endpoint"
                label="Endpoint"
                placeholder="/endpoint"
                rules={{ required: "Endpoint is required" }}
                sx={{ flexBasis: "95%" }}
              />
            </Stack>
            <Stack direction="row" gap={2}>
              <CodeEditor
                label="Query Params"
                value={queryParams}
                name="queryParams"
                onChange={setQueryParams}
                error={!isValidJson(queryParams)}
                sx={{ minHeight: "200px", flexBasis: "50%" }}
              />
              <CodeEditor
                label="Body"
                value={body}
                name="body"
                onChange={setBody}
                error={!isValidJson(body)}
                editable={true}
                sx={{ minHeight: "200px", flexBasis: "50%" }}
              />
            </Stack>
          </Form>
          <Divider />
          <CodeEditor
            data-testid="responseCodeEditor"
            label="Response"
            value={JSON.stringify(response, null, 2)}
            editable={false}
            name="response"
            sx={{ minHeight: "200px", flexBasis: "50%", flexGrow: 1 }}
          />
        </Stack>
      </Grid>
      <Grid xs={4}>
        <ToolboxHistory onClick={onToolboxHistoryClick} />
      </Grid>
    </Grid>
  )
}
