import {
  HealthcheckCreateRequest,
  HealthcheckDeleteRequest,
  Healthcheck,
  HealthcheckToggleRequest,
  HealthcheckToggleResponse,
  ByEnvQuery,
  ManualHealthcheck,
  HealthcheckTriggerRequest
} from "@liveops-portal/lib"
import { getError } from "@/helpers/errors"
import notify from "@/helpers/notify"
import { api } from "@/store/api"

export const healthcheckApi = api
  .enhanceEndpoints({ addTagTypes: ["Healthcheck"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getHealthchecks: builder.query<Healthcheck[], ByEnvQuery>({
        query: () => ({
          url: "healthchecks"
        }),
        providesTags: ["Healthcheck"],
        onQueryStarted({ env }, { queryFulfilled }) {
          queryFulfilled.catch((err) =>
            notify.error(
              `Could not get healthchecks for ${env} environment. ${getError(err)}`
            )
          )
        }
      }),
      toggleHealthcheck: builder.mutation<
        HealthcheckToggleResponse,
        HealthcheckToggleRequest
      >({
        query: ({ serviceName, enabled }) => ({
          url: `healthchecks/${serviceName}`,
          body: { enabled: !enabled },
          method: "PATCH"
        }),
        onQueryStarted({ serviceName, env }, { dispatch, queryFulfilled }) {
          queryFulfilled
            .then(({ data: toggledHealthcheck }) => {
              dispatch(
                healthcheckApi.util.updateQueryData(
                  "getHealthchecks",
                  { env },
                  (draft) => {
                    const healthcheckIndex = draft.findIndex(
                      (healthcheck) => healthcheck.serviceName === serviceName
                    )

                    if (healthcheckIndex !== -1) {
                      draft[healthcheckIndex] = {
                        ...draft[healthcheckIndex],
                        ...toggledHealthcheck
                      }
                    }

                    notify.success(
                      `${toggledHealthcheck.serviceName} healthcheck ${
                        /* c8 ignore next */ toggledHealthcheck.enabled
                          ? "enabled"
                          : "disabled"
                      } successfully.`
                    )
                  }
                )
              )
            })
            .catch((err) => {
              notify.error(`Could not toggle healthcheck. ${getError(err)}`)
            })
        }
      }),
      createHealthcheck: builder.mutation<
        Healthcheck,
        HealthcheckCreateRequest
      >({
        query: ({
          serviceName,
          enabled,
          subscriptionKey,
          qdOperationReason,
          url,
          msTeamsNotifierWebhookId
        }) => ({
          url: "healthchecks",
          method: "POST",
          body: {
            serviceName,
            enabled,
            url,
            subscriptionKey,
            msTeamsNotifierWebhookId
          },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: ["Healthcheck"],
        onQueryStarted({ serviceName }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(`${serviceName} created successfully.`)
            })
            .catch((err) => {
              notify.error(
                `${serviceName} could not be created. ${getError(err)}`
              )
            })
        }
      }),
      deleteHealthcheck: builder.mutation<void, HealthcheckDeleteRequest>({
        query: ({ serviceName }) => ({
          url: `healthchecks/${serviceName}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Healthcheck"],
        onQueryStarted({ serviceName }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(`${serviceName} removed successfully.`)
            })
            .catch((err) => {
              notify.error(
                `${serviceName} could not be removed. ${getError(err)}`
              )
            })
        }
      }),
      triggerHealthchecks: builder.mutation<
        ManualHealthcheck[],
        HealthcheckTriggerRequest
      >({
        query: (body) => ({
          url: "healthchecks/trigger",
          method: "POST",
          body
        }),
        invalidatesTags: ["Healthcheck"],
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(`Statuses have been updated successfully.`)
            })
            .catch((err) => {
              notify.error(
                `Something went wrong while checking the statuses. ${getError(err)}`
              )
            })
        }
      })
    }),
    overrideExisting: false
  })

export const {
  useGetHealthchecksQuery,
  useToggleHealthcheckMutation,
  useCreateHealthcheckMutation,
  useDeleteHealthcheckMutation,
  useTriggerHealthchecksMutation
} = healthcheckApi
