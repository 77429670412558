import { InteractionRequiredAuthError } from "@azure/msal-browser"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { MSAL_INSTANCE, MSAL_TOKEN_REQUEST } from "@/constants/auth"
import { selectOperationReason } from "@/store/slices/audit"
import { selectEnv } from "@/store/slices/context"

const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.__CLIENT_URL}/api/`,
  prepareHeaders: async (headers, { getState }) => {
    /* c8 ignore start */
    const qdOperationReason = selectOperationReason(getState())
    const currentLocale = localStorage.getItem("localization")

    if (!headers.has("env")) {
      const env = selectEnv(getState())
      env && headers.set("env", env)
    }

    const account = MSAL_INSTANCE.getActiveAccount()

    if (account) {
      const auth = await MSAL_INSTANCE.acquireTokenSilent({
        ...MSAL_TOKEN_REQUEST,
        account
      }).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          await MSAL_INSTANCE.acquireTokenRedirect({
            ...MSAL_TOKEN_REQUEST,
            account
          })
        }
      })

      if (!headers.has("Authorization") && auth) {
        headers.set("Authorization", `Bearer ${auth.accessToken}`)
      }

      if (!headers.has("QD-Username") && account) {
        headers.set("QD-Username", account.username)
      }

      if (!headers.has("QD-Operation-Reason") && qdOperationReason) {
        headers.set("QD-Operation-Reason", qdOperationReason)
      }
    }

    if (!headers.has("Content-Language") && currentLocale) {
      headers.set("Content-Language", currentLocale)
    }

    return headers
    /* c8 ignore end */
  }
})

/* Initial configuration of the RTK Query. Endpoints should be configured in respective features */
export const api = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const response = await baseQuery(args, api, extraOptions)

      if (response.error) {
        throw response.error.data
      }

      return {
        // Any used as a catch-all since this will apply to all endpoints
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: (response as any)?.data || null
      }
    } catch (error) {
      return { error }
    }
  },
  endpoints: () => ({})
})
