import { ReactNode } from "react"

export enum TAB_IDS {
  HEALTHCHECKS = "HEALTHCHECKS"
}

export type Tab = {
  title: string
  key: string
  icon?: ReactNode
  panel: ReactNode
}
