import { Typography } from "@mui/joy"
import React from "react"
import { Applications } from "@/components/applications/applications"
import { useAppSelector } from "@/hooks/store"
import { useGetAllApplicationsByGameIdQuery } from "@/store/api/application"
import { useGetAllBuildsByGameIdQuery } from "@/store/api/build"
import { useGetAllSpacesQuery } from "@/store/api/space"
import { selectEnv, selectGame, selectSpace } from "@/store/slices/context"

export const ApplicationsPage: React.FC = () => {
  const env = useAppSelector(selectEnv)
  const spaceId = useAppSelector(selectSpace)
  const gameId = useAppSelector(selectGame)

  const { data: spaces, isFetching: isFetchingSpaces } = useGetAllSpacesQuery(
    { env },
    { skip: !env }
  )

  const { data: applications, isFetching: isFetchingApplications } =
    useGetAllApplicationsByGameIdQuery(
      { gameId, env },
      { skip: !gameId || !env }
    )

  const { data: buildsByAppId, isFetching: isFetchingBuildsByAppId } =
    useGetAllBuildsByGameIdQuery({ gameId, env }, { skip: !gameId || !env })

  const isFetching =
    isFetchingSpaces || isFetchingApplications || isFetchingBuildsByAppId

  return !spaceId && !gameId ? (
    <Typography>
      Please select a Game or Space to view configured application redirections.
    </Typography>
  ) : isFetching ? (
    <Typography>Loading...</Typography>
  ) : applications?.length && buildsByAppId ? (
    <Applications
      applications={applications}
      builds={buildsByAppId}
      spaces={spaces}
    />
  ) : (
    <Typography>There are no applications configured for this game.</Typography>
  )
}
