import { mockSpace } from "../mocks"
import {
  BySpaceIdQuery,
  ConfigForSpaceId,
  ConfigUpdateRequest
} from "../typings"

export const mockConfig: ConfigForSpaceId = {
  configKey: {
    randomData: "hello"
  },

  anotherKey: {
    randomData: "it's"
  },

  yetAnotherKey: {
    randomData: "me"
  },

  general: {
    public: {
      spaceName: "xxxxxx",
      spacePrefix: "xxx_"
    }
  },

  "my-service": {
    public: {
      enabled: true
    }
  },

  unimatrix: {
    private: {
      gameServerBaseUrl: "gameServerBaseUrl",
      xyqBaseUrl: "xyqBaseUrl",
      roleId: "roleId",
      gameId: "gameId",
      appId: "appId",
      appSecret: "appSecret"
    }
  },

  unipublish: {
    private: {
      baseUrl: "baseUrl",
      gameId: "gameId",
      signatureKey: "sign"
    }
  }
}

export const mockFindConfigBySpaceIdRequest: BySpaceIdQuery = {
  spaceId: mockSpace.spaceId
}

export const mockUpdateConfigRequest: ConfigUpdateRequest = {
  spaceId: mockSpace.spaceId,
  configName: "mock-service-online-dev",
  jsonData: { ...mockConfig, yetAnotherKey: { randomData: "you" } }
}
