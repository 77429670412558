import { NavPage } from "@liveops-portal/lib"
import {
  ColorPaletteProp,
  List,
  ListItem,
  ListItemButton,
  listItemButtonClasses
} from "@mui/joy"
import { Collapse } from "@mui/material"
import React from "react"
import { useLocation } from "react-router-dom"
import { ConditionalWrapper } from "@/components/conditional-wrapper/conditional-wrapper"
import { ContextLink } from "@/components/context-link/context-link"

interface Props {
  open?: boolean
  parent: string
  pages: NavPage[]
  color: ColorPaletteProp
  expanded: boolean
}

export const SidebarSubMenu: React.FC<Props> = ({
  open,
  pages,
  parent,
  color,
  expanded
}: Props) => {
  const { pathname } = useLocation()

  const { root: rootClass, selected: selectedClass } = listItemButtonClasses

  return (
    <List
      size="sm"
      variant={expanded ? "soft" : "outlined"}
      aria-expanded={open}
      data-testid={`sidebar-submenu-${parent}`}
      sx={{
        ...(!expanded && {
          borderRadius: "sm",
          overflow: "hidden",
          minWidth: 175,
          bgcolor: "background.body"
        })
      }}
    >
      <ConditionalWrapper
        condition={expanded}
        component={<Collapse in={open} timeout={200} />}
      >
        {pages?.map(({ id: child, name, disabled }) => {
          const slug = child !== "overview" ? `/${child}` : ""
          const to = `/${parent}${slug}`
          const selected = to === pathname

          return (
            <ListItem
              key={child}
              sx={{
                ...(!expanded && { "--ListItem-paddingRight": 0 })
              }}
            >
              <ListItemButton
                component={!disabled ? ContextLink : "div"}
                to={!disabled ? to : undefined}
                selected={selected}
                disabled={disabled}
                underline="none"
                sx={(theme) => ({
                  fontSize: "sm",
                  borderWidth: 0,
                  borderLeftWidth: 2,
                  ...(!expanded && { px: theme.spacing(2) }),
                  [`&.${rootClass}.${selectedClass}`]: {
                    borderLeftColor: theme.palette[color].solidBg,
                    bgcolor: "transparent"
                  },
                  [`&.${rootClass}:hover`]: {
                    bgcolor: "neutral.softHoverBg"
                  }
                })}
              >
                {name}
              </ListItemButton>
            </ListItem>
          )
        })}
      </ConditionalWrapper>
    </List>
  )
}
