import { AuditLogsRequest, AuditLogsResponse } from "@liveops-portal/lib"
import { getError } from "@/helpers/errors"
import notify from "@/helpers/notify"
import { api } from "@/store/api/index"

export const auditApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAudits: builder.query<AuditLogsResponse, AuditLogsRequest>({
      query: ({ duration }) => ({
        url: "audits",
        params: { duration }
      }),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled.catch((err) =>
          notify.error(`Error fetching audit logs: ${getError(err)}`)
        )
      }
    })
  }),
  overrideExisting: false
})

export const { useGetAuditsQuery } = auditApi
