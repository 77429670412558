export const sortObjectsWithKeyword = <T>(
  array: T[] | undefined,
  sortBy: keyof T,
  keyword: string = "default",
  order: "asc" | "desc" = "asc",
  defaultPosition: "first" | "last" = "first"
): T[] => {
  if (!array) return []

  const newArray = array.slice()
  return newArray.sort((a, b) => {
    const valueA = a[sortBy]
    const valueB = b[sortBy]

    if (valueA === keyword && valueB !== keyword) {
      return defaultPosition === "first" ? -1 : 1
    } else if (valueA !== keyword && valueB === keyword) {
      return defaultPosition === "first" ? 1 : -1
    } else {
      const numA = isNaN(Number(valueA)) ? null : Number(valueA)
      const numB = isNaN(Number(valueB)) ? null : Number(valueB)

      if (numA !== null && numB !== null) {
        if (numA < numB) return order === "asc" ? -1 : 1
        if (numA > numB) return order === "asc" ? 1 : -1
        return 0
      }

      if (valueA < valueB) return order === "asc" ? -1 : 1
      if (valueA > valueB) return order === "asc" ? 1 : -1
      return 0
    }
  })
}

export const sortStringsWithKeyword = (
  array: string[] | undefined,
  keyword: string = "default",
  order: "asc" | "desc" = "asc",
  defaultPosition: "first" | "last" = "first"
): string[] => {
  if (!array) return []

  const newArray = array.slice()
  return newArray.sort((a, b) => {
    if (a === keyword && b !== keyword) {
      return defaultPosition === "first" ? -1 : 1
    } else if (a !== keyword && b === keyword) {
      return defaultPosition === "first" ? 1 : -1
    } else {
      if (a < b) return order === "asc" ? -1 : 1
      if (a > b) return order === "asc" ? 1 : -1
      return 0
    }
  })
}
