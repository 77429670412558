import { formatJsonToString } from "@liveops-portal/lib"
import { List, ListItem, Stack, Typography } from "@mui/joy"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { useAppSelector } from "@/hooks/store"
import { useGetGameDataBySeasonIdQuery } from "@/store/api/game-data"
import { useGetSeasonsQuery } from "@/store/api/season"
import { selectEnv, selectGame, selectSeason } from "@/store/slices/context"

/**
 * Component representing the Season Designer DataViewer page.
 */
export const SeasonDesignerDataViewerPage: React.FC = () => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)
  const { t } = useTranslation()

  const {
    data: seasons,
    isUninitialized: isUninitializedSeasons,
    isFetching: isFetchingSeasons
  } = useGetSeasonsQuery({ gameId, env }, { skip: !gameId || !env })

  const {
    data: gameData,
    isUninitialized: isUninitializedGameData,
    isFetching: isFetchingGameData
  } = useGetGameDataBySeasonIdQuery(
    { gameId, seasonId, env },
    { skip: !seasonId || !gameId || !env }
  )

  const isFetching = isFetchingSeasons || isFetchingGameData
  const isUninitialized = isUninitializedSeasons || isUninitializedGameData

  const activeSeason = useMemo(
    () => seasons?.find((s) => s.seasonId === seasonId),
    [seasons, seasonId]
  )

  return isUninitialized ? (
    <Typography>{t("_seasonDesigner.dataViewer.uninitialized")}</Typography>
  ) : isFetching ? (
    <Typography>{t("_common.loading")}</Typography>
  ) : gameData && Object.values(gameData).length ? (
    <Stack gap={0.5} flexGrow={1}>
      <List
        sx={{
          p: 0,
          flexGrow: 0,
          "--ListItem-minHeight": 0,
          "--ListItem-paddingX": 0
        }}
      >
        <ListItem>
          <Typography level="title-sm">
            {t("_seasonDesigner.dataViewer.dataVersion")}
          </Typography>
          <Typography level="body-sm">
            <CopyToClipboard>{activeSeason?.clientDataVersion}</CopyToClipboard>
          </Typography>
        </ListItem>
      </List>
      <Stack flexGrow={1} gap={1} direction="row" position="relative">
        <CodeEditor
          editable={false}
          name="gameData"
          value={formatJsonToString(gameData)}
          label={t("_seasonDesigner.dataViewer.gameData")}
          sx={{ flexBasis: "100%" }}
        />
      </Stack>
    </Stack>
  ) : (
    <Typography>
      {t("_seasonDesigner.dataViewer.noItems", {
        seasonName: activeSeason?.seasonName
      })}
    </Typography>
  )
}
