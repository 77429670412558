import { NavSection } from "@liveops-portal/lib"
import {
  Box,
  ColorPaletteProp,
  List,
  ListItem,
  ListSubheader,
  listItemClasses
} from "@mui/joy"
import React from "react"
import { SidebarItem } from "@/components/sidebar-item/sidebar-item"
import { useAppSelector } from "@/hooks/store"
import { selectIsSidebarExpanded } from "@/store/slices/app"

interface Props extends NavSection {
  color: ColorPaletteProp
}

export const SidebarMenu: React.FC<Props> = ({ name, items, color }: Props) => {
  const isSidebarExpanded = useAppSelector(selectIsSidebarExpanded)

  return (
    <ListItem
      nested
      sx={(theme) => ({
        "--Icon-color": theme.palette.text.tertiary,
        "--Icon-fontSize": theme.fontSize.xl,
        "--ListItemDecorator-size": theme.spacing(4),
        "--ListItem-paddingLeft": theme.spacing(2),
        [`&.${listItemClasses.root} + .${listItemClasses.root}`]: {
          borderTop: Number(isSidebarExpanded),
          borderTopColor: "divider"
        }
      })}
    >
      <ListSubheader
        sx={{
          pt: 2,
          pb: 1,
          fontSize: "xs",
          fontWeight: isSidebarExpanded ? 600 : 800,
          color: isSidebarExpanded ? `${color}.solidBg` : "neutral.500"
        }}
      >
        <Box
          sx={{
            pl: isSidebarExpanded ? 0 : "5px",
            transition: "padding 0.2s"
          }}
        >
          {isSidebarExpanded ? name : "—"}
        </Box>
      </ListSubheader>
      <List sx={(theme) => ({ "--ListItem-minHeight": theme.spacing(5) })}>
        {items.map((item) => (
          <SidebarItem
            key={item.id}
            expanded={isSidebarExpanded}
            color={color}
            {...item}
          />
        ))}
      </List>
    </ListItem>
  )
}
