import { Typography, Stack } from "@mui/joy"
import { PropsWithChildren, ReactElement, useState } from "react"
import React from "react"
import { PageContext } from "@/hooks/usePageActions"
import { useUpdateContextParams } from "@/hooks/useUpdateContextParams"

interface Props extends PropsWithChildren {
  title: string
}

export const Page = ({ title, children }: Props) => {
  useUpdateContextParams()
  const [pageActions, setPageActions] = useState<ReactElement[]>([])

  return (
    <PageContext.Provider
      value={{ pageActions: [pageActions, setPageActions] }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        gap={3}
        mb={2}
      >
        <Typography level="h2" component="h1" mt="-5px">
          {title}
        </Typography>
        {!!pageActions?.length && (
          <Stack direction="row" gap={1}>
            {React.Children.map(pageActions, (child) => child)}
          </Stack>
        )}
      </Stack>

      <Stack flexGrow={1} component="section">
        {children}
      </Stack>
    </PageContext.Provider>
  )
}
