import { AllGamesQuery, Game } from "@liveops-portal/lib"
import { api } from "@/store/api"

export const gameApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllGames: builder.query<Game[], AllGamesQuery>({
      query: ({ includeMarkedForDeletion }) => ({
        url: `games`,
        params: { includeMarkedForDeletion }
      })
    })
  }),
  overrideExisting: false
})

export const { useGetAllGamesQuery, useLazyGetAllGamesQuery } = gameApi
