import { Card, Typography } from "@mui/joy"
import { Unstable_Grid2 as Grid } from "@mui/material"

import React from "react"
import { OverviewApplications } from "@/components/overview-applications/overview-applications"
import { OverviewEntity } from "@/components/overview-entity/overview-entity"
import { OverviewTree } from "@/components/overview-tree/overview-tree"
import { useAppSelector } from "@/hooks/store"
import { useGetAllApplicationsByGameIdQuery } from "@/store/api/application"
import { useGetConfigBySpaceIdQuery } from "@/store/api/config"
import { useGetAllGamesQuery } from "@/store/api/game"
import { useGetAllSpacesQuery } from "@/store/api/space"
import { selectEnv, selectGame, selectSpace } from "@/store/slices/context"

export const OverviewPage: React.FC = () => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const { isFetching: isFetchingSpaces, isError: isErrorSpaces } =
    useGetAllSpacesQuery({ env }, { skip: !env })
  const { isFetching: isFetchingGames, isError: isErrorGames } =
    useGetAllGamesQuery({ env }, { skip: !env })
  const { isFetching: isFetchingApplications, isError: isErrorApplications } =
    useGetAllApplicationsByGameIdQuery(
      { gameId, env },
      { skip: !gameId || !env }
    )
  const { isFetching: isFetchingConfig, isError: isErrorConfig } =
    useGetConfigBySpaceIdQuery({ spaceId }, { skip: !spaceId })

  const isFetching =
    isFetchingSpaces ||
    isFetchingApplications ||
    isFetchingGames ||
    isFetchingConfig

  const isError =
    isErrorSpaces || isErrorApplications || isErrorGames || isErrorConfig

  return (
    <>
      {isFetching ? (
        <Typography level="body-md">Loading...</Typography>
      ) : isError ? (
        <Typography level="body-md">
          Something went wrong. Please try again later.
        </Typography>
      ) : (
        <Grid container spacing={2} flexGrow={1}>
          <Grid xs={2} display="flex" flexGrow={1} flexDirection="column">
            <OverviewTree />
          </Grid>
          <Grid xs={10} display="flex" flexGrow={1} flexDirection="column">
            <Card>
              <OverviewEntity />
              <OverviewApplications />
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  )
}
