import { Game, Space } from "@liveops-portal/lib"
import { Chip, Divider, List, ListItem, Typography } from "@mui/joy"

import React, { useMemo } from "react"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { useAppSelector } from "@/hooks/store"
import { useGetConfigBySpaceIdQuery } from "@/store/api/config"
import { useGetAllGamesQuery } from "@/store/api/game"
import { useGetAllSpacesQuery } from "@/store/api/space"
import { selectEnv, selectSpace, selectGame } from "@/store/slices/context"

export const OverviewEntity: React.FC = () => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const { data: spaces } = useGetAllSpacesQuery({ env }, { skip: !env })
  const { data: games } = useGetAllGamesQuery({ env }, { skip: !env })
  const { data: configurations } = useGetConfigBySpaceIdQuery(
    { spaceId },
    { skip: !spaceId }
  )

  const entity: Game | Space | undefined = useMemo(
    () =>
      spaceId
        ? spaces?.find((s) => s.spaceId === spaceId)
        : games?.find((g) => g.gameId === gameId),
    [spaceId, spaces, gameId, games]
  )

  const entityType = entity && "spaceId" in entity ? "space" : "game"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const config = configurations as any

  return (
    entity && (
      <>
        <Typography
          level="h3"
          alignItems="center"
          endDecorator={
            <Chip color={entityType} sx={{ mb: "-5px" }}>
              {entityType}
            </Chip>
          }
          data-testid="name"
        >
          {"name" in entity ? entity.name : entity.spaceName}
        </Typography>
        <List
          sx={{
            p: 0,
            "--ListItem-minHeight": 0,
            "--ListItem-paddingX": 0
          }}
        >
          <ListItem>
            <Typography level="title-sm">UUID</Typography>
            <Typography level="body-sm" data-testid="uuid">
              <CopyToClipboard>
                {"spaceId" in entity ? entity.spaceId : entity.gameId}
              </CopyToClipboard>
            </Typography>
          </ListItem>
          {entityType === "space" && config && (
            <>
              <ListItem>
                <Typography level="title-sm">Space prefix</Typography>
                <Typography level="body-sm" data-testid="prefix">
                  {config?.general?.public?.spacePrefix || "❌"}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography level="title-sm">Udata</Typography>
                <Typography level="body-sm" data-testid="udata">
                  {config?.udata ? "✅" : "❌"}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography level="title-sm">Unimatrix</Typography>
                <Typography level="body-sm" data-testid="unimatrix">
                  {config?.unimatrix ? "✅" : "❌"}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography level="title-sm">Unipublish</Typography>
                <Typography level="body-sm" data-testid="unipublish">
                  {config?.unipublish ? "✅" : "❌"}
                </Typography>
              </ListItem>
            </>
          )}
        </List>
        <Divider inset="context" />
      </>
    )
  )
}
