export const enUS = {
  translation: {
    _common: {
      loading: "Loading...",
      draft: "Draft",
      published: "Published",
      lastModified: "Last modified",
      none: "None",
      duration: "Duration",
      itemName: "{{item}} name",
      this: "this",
      weeks_one: "week",
      weeks_other: "weeks",
      days_one: "day",
      days_other: "days"
    },
    _actions: {
      actions: "Actions",
      update: "Update",
      publish: "Publish",
      delete: "Delete",
      refresh: "Refresh",
      add: "Add",
      remove: "Remove"
    },
    _itemActions: {
      expand: "Expand {{item}}",
      collapse: "Collapse {{item}}",
      create: {
        button: "Create {{item}}",
        title: "Create a {{item}}",
        submit: "Create"
      },
      update: {
        title: "Update {{item}}",
        submit: "Update"
      }
    },
    _app: {
      stageWarning:
        "You're currently using the portal stage intended for developers. Please use the <0>live portal</0> instead."
    },
    _forms: {
      reason: "Reason",
      placeholders: {
        select: "Choose one...",
        reason: "Please provide a short reason for this operation"
      },
      errors: {
        required: "{{label}} is required",
        min: "{{label}} is not a valid number",
        max: "{{label}} exceeds limit",
        minLength: "{{label}} is too short",
        maxLength: "{{label}} exceeds character limit"
      }
    },
    _featureSwitches: {
      overridden: "Overridden",
      defaultValue: "Default value",
      currentValue: "Current value",
      valueOverride: "Value override",
      overrideDefaultValue: "Override default value",
      typeCannotBeShown: "Types other than booleans cannot be currently shown."
    },
    _auditLogs: {
      empty: "Audit log is empty.",
      duration: "Duration (days)",
      username: "Username",
      serviceName: "Service Name"
    },
    _userCard: {
      switchTo: "Switch to Chinese (Simplified)",
      otherLanguageCode: "CN"
    },
    _seasonDesigner: {
      overview: {
        uninitialized: "Please choose a game to view the associated seasons.",
        noItems: "No seasons found for selected game.",
        seasonName: "Season name",
        clientData: "Client data",
        clientDataVersion: "Client data version",
        latestPublishedRevision: "Latest published revision",
        create: {
          button: "Create season",
          title: "Create a season",
          submit: "Create"
        },
        update: {
          title: "Update season",
          submit: "Update"
        },
        errors: {
          seasonName: "Season name is required",
          duration: "Duration is not a valid number",
          clientDataVersion: "A client data version number is required"
        }
      },
      dataViewer: {
        uninitialized:
          "Please choose a season to view the associated data version.",
        noItems: "No data available for season {{seasonName}}.",
        dataVersion: "Data version",
        preserved: "Preserved",
        state: "State",
        gameData: "Game data"
      },
      challenges: {
        uninitialized:
          "Please choose a season to view the associated challenges.",
        noItems: "No challenges available for season {{seasonName}}.",
        revisionWarning:
          "Only the challenges associated with the current draft revision of the season are listed here.",
        displayName: "Challenge name",
        filter: "Filter",
        progressInMenu: "Progress in menu",
        scope: "Scope",
        levels: "Levels",
        threshold: "Threshold",
        reward: "Reward",
        scopes: {
          season: "Season"
        },
        progressType: "Progress type",
        progressTypes: {
          highest: "Highest",
          accumulation: "Accumulation"
        },
        create: {
          button: "Create challenge",
          title: "Create a challenge",
          submit: "Create"
        },
        update: {
          title: "Update challenge",
          submit: "Update"
        },
        errors: {
          scope: "Scope is required",
          displayName: "Challenge name is required",
          progressType: "Progress type is required",
          filter: "Filter is required",
          threshold: {
            required: "Threshold is required",
            min: "Threshold is not a valid number"
          },
          reward: "Reward is required"
        }
      },
      battlepass: {
        item: "battlepass",
        uninitialized:
          "Please choose a season to view the associated battlepasses.",
        noItems: "No battlepasses available for season {{seasonName}}.",
        durationTypes: {
          fullSeason: "Full Season",
          specificLength: "Specific length"
        },
        delay: "Delay",
        delayHint: "From season start",
        length: "Length",
        xpRequired: "XP required",
        xpRequiredTypes: {
          linear: "Linear",
          custom: "Custom per level"
        },
        xpPerLevel: "XP per level",
        levels_one: "Level",
        levels_other: "Levels",
        levelsRewards: "Level rewards",
        levelsRewardsLabel: "Track {{trackIdx}}, level {{levelIdx}} rewards",
        defaultTrackname: "Free",
        tracks_one: "track",
        tracks_other: "tracks",
        create: {
          button: "Create battlepass",
          title: "Create a battlepass",
          submit: "Create"
        },
        update: {
          title: "Update battlepass",
          submit: "Update"
        },
        errors: {
          duration: {
            delay: {
              max: "Delay cannot be longer than season duration"
            },
            length: {
              max: "Length cannot be longer than season duration"
            }
          }
        }
      }
    }
  }
}
