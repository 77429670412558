import { Typography } from "@mui/joy"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { AuditDurationPicker } from "@/components/audit-duration-picker/audit-duration-picker"
import { AuditTable } from "@/components/audit-table/audit-table"
import { usePageActions } from "@/hooks/usePageActions"
import { useGetAuditsQuery } from "@/store/api/audit"

export const AuditPage: React.FC = () => {
  const { t } = useTranslation()
  const [duration, setDuration] = useState<number>(15)

  const { data: audits, isFetching, refetch } = useGetAuditsQuery({ duration })

  usePageActions([
    <AuditDurationPicker
      onChange={(_, value) => {
        value && setDuration(value)
        refetch()
      }}
    />
  ])

  return isFetching ? (
    <Typography>{t("_common.loading")}</Typography>
  ) : audits?.length ? (
    <AuditTable data={audits} />
  ) : (
    <Typography>{t("_auditLogs.empty")}</Typography>
  )
}
