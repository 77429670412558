import { PropsWithChildren, ReactElement, forwardRef } from "react"

interface Props<T> extends PropsWithChildren {
  condition: boolean
  component: ReactElement<T>
}

const ConditionalWrapperComponent = <T,>(
  { children, condition, component, ...props }: Props<T>,
  ref: React.Ref<T>
) => {
  const Component = component.type
  return condition ? (
    <Component {...component.props} {...props} ref={ref}>
      {children}
    </Component>
  ) : (
    <div {...props}>{children}</div>
  )
}

export const ConditionalWrapper = forwardRef(ConditionalWrapperComponent)
