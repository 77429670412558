import { Environments } from "@liveops-portal/lib"
import { Chip, Option, Select } from "@mui/joy"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import { isValidParam } from "@/helpers/params"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useGetEnvironmentsQuery } from "@/store/api/environment"
import { selectStage } from "@/store/slices/app"
import { selectEnv, setEnv } from "@/store/slices/context"

export const EnvSelector: React.FC = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { data: envs } = useGetEnvironmentsQuery()
  const stage = useAppSelector(selectStage)
  const env = useAppSelector(selectEnv)
  const envInit = useRef(false)
  const envParam = useMemo(() => searchParams.get("env"), [searchParams])
  const isValidEnv = useMemo(
    () => isValidParam(envParam, envs),
    [envParam, envs]
  )
  const defaultEnv = useMemo(
    () =>
      stage === Environments.LIVE ? Environments.DEV : Environments.ONLINEDEV,
    [stage]
  )

  const initializeEnv = useCallback(() => {
    dispatch(setEnv(envParam != null && isValidEnv ? envParam : defaultEnv))
  }, [defaultEnv, dispatch, envParam, isValidEnv])

  useEffect(() => {
    if (isValidEnv == null) return
    if (!envInit.current) {
      initializeEnv()
      envInit.current = true
    }
  }, [isValidEnv, initializeEnv])

  return (
    envs?.length &&
    env != null && (
      <Select
        color={env}
        data-testid="env-selector"
        listboxId="env-selector"
        startDecorator={<Chip color={env}>Env</Chip>}
        variant="soft"
        value={env}
        onChange={(_, value) => {
          dispatch(setEnv(value))
        }}
        slotProps={{
          listbox: {
            variant: "outlined",
            color: "neutral",
            sx: { p: 0 }
          }
        }}
      >
        {envs.map((environment) => (
          <Option
            variant="plain"
            color="neutral"
            key={environment}
            label={environment}
            value={environment}
          >
            {environment}
          </Option>
        ))}
      </Select>
    )
  )
}
