import "./instrument"
import {
  EventMessage,
  EventType,
  AuthenticationResult
} from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import {
  CssBaseline,
  GlobalStyles,
  CssVarsProvider as JoyCssVarsProvider
} from "@mui/joy"
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID
} from "@mui/material"
import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import DurationPlugin from "dayjs/plugin/duration"
import isTodayPlugin from "dayjs/plugin/isToday"
import isYesterdayPlugin from "dayjs/plugin/isYesterday"
import UTCPlugin from "dayjs/plugin/utc"
import { IconoirProvider } from "iconoir-react"
import ReactDOM from "react-dom/client"
import { I18nextProvider } from "react-i18next"
import { Provider as StoreProvider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { MSAL_INSTANCE } from "@/constants/auth"
import i18next from "@/i18n"
import { ErrorPage } from "@/pages/error-page/error-page"
import { Routes } from "@/routes"
import store from "@/store"
import { globalStyles, toastifyStyles } from "@/styles"
import theme from "@/styles/theme"

// Dynamically add data-attributes (such as data-testid) to HTML Attributes types
type DataAttributeKey = `data-${string}`
declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    [dataAttribute: DataAttributeKey]: unknown
  }
}

const prefersDarkMode =
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches

dayjs.extend(DurationPlugin)
dayjs.extend(UTCPlugin)
dayjs.extend(isTodayPlugin)
dayjs.extend(isYesterdayPlugin)

// Localization management
const savedLocalization = localStorage.getItem("localization")

if (savedLocalization) {
  i18next.changeLanguage(savedLocalization)
  Sentry.setTag("locale", savedLocalization)
} else {
  localStorage.setItem("localization", "en-US")
  Sentry.setTag("locale", "en-US")
}

// TODO: Add color to MaterialCssVarsProvider theme
const materialTheme = materialExtendTheme()

MSAL_INSTANCE.initialize().then(() => {
  if (
    !MSAL_INSTANCE.getActiveAccount() &&
    MSAL_INSTANCE.getAllAccounts().length > 0
  ) {
    MSAL_INSTANCE.setActiveAccount(MSAL_INSTANCE.getAllAccounts()[0])
  }

  MSAL_INSTANCE.enableAccountStorageEvents()

  MSAL_INSTANCE.addEventCallback(
    ({ eventType, payload, error }: EventMessage) => {
      if (
        (eventType === EventType.SSO_SILENT_SUCCESS ||
          eventType === EventType.LOGIN_SUCCESS ||
          eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        (payload as AuthenticationResult)?.account
      ) {
        MSAL_INSTANCE.setActiveAccount(
          (payload as AuthenticationResult).account
        )
      } else if (
        eventType === EventType.SSO_SILENT_FAILURE ||
        eventType === EventType.LOGIN_FAILURE ||
        eventType === EventType.ACQUIRE_TOKEN_FAILURE
      ) {
        Sentry.captureException(error)
      }
    }
  )

  ReactDOM.createRoot(document.getElementById("root")!).render(
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
      <MsalProvider instance={MSAL_INSTANCE}>
        <StoreProvider store={store}>
          <Router>
            <MaterialCssVarsProvider
              defaultMode={prefersDarkMode ? "dark" : "light"}
              modeStorageKey="mode"
              colorSchemeStorageKey="color-scheme"
              theme={{ [MATERIAL_THEME_ID]: materialTheme }}
            >
              <JoyCssVarsProvider
                defaultMode={prefersDarkMode ? "dark" : "light"}
                modeStorageKey="mode"
                colorSchemeStorageKey="color-scheme"
                theme={theme}
              >
                <CssBaseline />
                <GlobalStyles styles={globalStyles} />
                <GlobalStyles styles={toastifyStyles} />
                <IconoirProvider
                  iconProps={{
                    className: "iconoir",
                    strokeWidth: 2
                  }}
                >
                  <I18nextProvider i18n={i18next}>
                    <Routes />
                  </I18nextProvider>
                </IconoirProvider>
              </JoyCssVarsProvider>
            </MaterialCssVarsProvider>
          </Router>
        </StoreProvider>
      </MsalProvider>
    </Sentry.ErrorBoundary>
  )
})
