import { Action, Actions } from "@liveops-portal/lib"
import { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormSelect } from "@/components/form-select/form-select"
import { Modal } from "@/components/modal/modal"
import { useAppSelector } from "@/hooks/store"
import { useGetGameDataVersionsQuery } from "@/store/api/game-data"
import { selectGame, selectEnv } from "@/store/slices/context"

interface Props<T extends FieldValues> {
  open: boolean
  loading: boolean
  action: Action
  methods: UseFormReturn<T>
  onSubmit: SubmitHandler<T>
  onClose: () => void
}

export const SeasonModal = <T extends FieldValues>({
  open,
  loading,
  action,
  methods,
  onClose,
  onSubmit
}: Props<T>) => {
  const { t } = useTranslation()

  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const skip = !gameId || !env
  const { data: gameDataVersions } = useGetGameDataVersionsQuery(
    { gameId, env },
    { skip }
  )

  return (
    !!gameDataVersions && (
      <Modal
        open={open}
        onClose={onClose}
        title={t(`_seasonDesigner.overview.${action}.title`)}
      >
        <Form
          auditable
          methods={methods}
          loading={loading}
          submitLabel={t(`_seasonDesigner.overview.${action}.submit`)}
          onSubmit={onSubmit}
          gap={2}
          direction="column"
        >
          <FormInput
            type="text"
            name="seasonName"
            disabled={action === Actions.update}
            label={t("_seasonDesigner.overview.seasonName")}
            rules={{
              required: t("_seasonDesigner.overview.errors.seasonName")
            }}
          />

          <FormInput
            type="number"
            name="seasonRevisionDetails.duration"
            label={t("_common.duration")}
            rules={{
              required: t("_seasonDesigner.overview.errors.duration"),
              min: {
                value: 1,
                message: t("_seasonDesigner.overview.errors.duration")
              }
            }}
            endDecorator={<>{t("_common.weeks_other")}</>}
            slotProps={{ input: { min: 1, max: 1000 } }}
          />

          <FormSelect
            name="seasonRevisionDetails.clientDataVersion"
            label={t("_seasonDesigner.overview.clientDataVersion")}
            options={
              gameDataVersions.map((gameDataVersion) => ({
                value: gameDataVersion,
                label: gameDataVersion
              })) || []
            }
            rules={{
              required: t("_seasonDesigner.overview.errors.clientDataVersion")
            }}
          />
        </Form>
      </Modal>
    )
  )
}
