import { Environment, Game, Season, Space } from "@liveops-portal/lib"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { GlobalState } from "@/typings/redux"

export type ContextState = {
  type: string[]
  env: Environment | null
  spaceId: Space["spaceId"] | null
  gameId: Game["gameId"] | null
  seasonId: Season["seasonId"] | null
}

export const contextSlice = createSlice({
  name: "context",
  initialState: {
    type: [],
    env: null,
    gameId: null,
    spaceId: null,
    seasonId: null
  } as ContextState,
  reducers: {
    setContext: (
      state: ContextState,
      { payload: context }: PayloadAction<ContextState["type"]>
    ) => {
      state.type = context
    },
    setEnv: (
      state: ContextState,
      { payload: env }: PayloadAction<ContextState["env"]>
    ) => {
      state.env = env
    },
    setGameId: (
      state: ContextState,
      { payload: gameId }: PayloadAction<ContextState["gameId"]>
    ) => {
      state.gameId = gameId
    },
    setSpaceId: (
      state: ContextState,
      { payload: spaceId }: PayloadAction<ContextState["spaceId"]>
    ) => {
      state.spaceId = spaceId
    },
    setSeasonId: (
      state: ContextState,
      { payload: seasonId }: PayloadAction<ContextState["seasonId"]>
    ) => {
      state.seasonId = seasonId
    }
  }
})

/* Actions */
export const { setContext, setEnv, setGameId, setSpaceId, setSeasonId } =
  contextSlice.actions

/* Selectors */
export const selectContext = (state: GlobalState) => state.context.type
export const selectEnv = (state: GlobalState) => state.context.env
export const selectGame = (state: GlobalState) => state.context.gameId
export const selectSpace = (state: GlobalState) => state.context.spaceId
export const selectSeason = (state: GlobalState) => state.context.seasonId

export default contextSlice.reducer
