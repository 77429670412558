import { IconType } from "@liveops-portal/lib"
import {
  MenuButton,
  IconButton,
  Menu,
  MenuItem,
  Dropdown,
  cardClasses,
  ListItemDecorator,
  IconButtonProps
} from "@mui/joy"
import { EditPencil, Journal, MoreHoriz, TrashSolid } from "iconoir-react"
import React, { MouseEventHandler } from "react"
import { useTranslation } from "react-i18next"

type Action = "update" | "publish" | "delete"

interface Props {
  active?: boolean
  actions: Partial<
    Record<Action, { handler: MouseEventHandler; disabled?: boolean }>
  >
}

export const CardActions: React.FC<Props> = ({ active, actions }: Props) => {
  const { t } = useTranslation()

  const keys = Object.keys(actions)
  const isSingleAction = keys.length === 1

  const Icons: Record<Action, IconType> = {
    update: EditPencil,
    publish: Journal,
    delete: TrashSolid
  }

  let Icon: IconType | null = isSingleAction ? Icons[keys[0] as Action] : null

  const buttonProps: Partial<IconButtonProps> = {
    variant: "solid",
    color: "neutral",
    size: "sm"
  }

  const buttonStyle = {
    borderRadius: "50%",
    transition: "opacity 0.2s",
    transform: "scale(1) translateX(50%) translateY(-50%)",
    transformOrigin: "100% 0%",
    position: "absolute",
    cursor: "pointer",
    top: "5px",
    right: "5px",
    opacity: 0,
    visibility: "hidden",

    ...(!active && {
      [`.${cardClasses.root}:hover &, &[aria-expanded=true]`]: {
        opacity: 1,
        visibility: "visible"
      }
    })
  }

  return isSingleAction ? (
    <IconButton
      {...buttonProps}
      color={keys[0] === "delete" ? "danger" : "neutral"}
      sx={buttonStyle}
      onClick={actions[keys[0] as Action]?.handler}
      disabled={actions[keys[0] as Action]?.disabled}
      aria-label={t(`_actions.${keys[0]}`)}
    >
      {Icon && <Icon />}
    </IconButton>
  ) : (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: buttonProps }}
        sx={buttonStyle}
        aria-label={t(`_actions.actions`)}
      >
        <MoreHoriz />
      </MenuButton>
      <Menu
        placement="bottom-end"
        size="sm"
        variant="outlined"
        disablePortal
        sx={{
          "--ListDivider-gap": "0",
          "--ListItemDecorator-size": "24px"
        }}
      >
        {Object.keys(actions).map((_a) => {
          const action = _a as Action
          Icon = Icons[action]

          return (
            <MenuItem
              key={action}
              color={action === "delete" ? "danger" : "neutral"}
              variant={action === "delete" ? "soft" : "plain"}
              onClick={actions[action]?.handler}
              disabled={actions[action]?.disabled}
              aria-label={t(`_actions.${action}`)}
            >
              <ListItemDecorator>
                <Icon />
              </ListItemDecorator>
              {t(`_actions.${action}`)}
            </MenuItem>
          )
        })}
      </Menu>
    </Dropdown>
  )
}
