import { OperationReason } from "./audit.typings"
import { Space } from "./space.typings"

export enum FeatureSwitchValueTypes {
  OVERRIDDEN = "_featureSwitches.overridden"
}

export interface BooleanFeatureSwitch<T extends boolean> {
  switchName: string
  switchType: string
  switchValue: T
  defaultValue: T
  overrideValue: T | null
}

export interface OtherFeatureSwitch {
  switchName: string
  switchType: string
  switchValue: unknown
  defaultValue: unknown
  overrideValue: unknown | null
}

export interface BooleanFeatureSwitchSetRequest
  extends Pick<BooleanFeatureSwitch<boolean>, "switchName" | "switchValue"> {
  spaceId: Space["spaceId"]
  qdOperationReason?: OperationReason["qdOperationReason"]
}

export interface FeatureSwitchGetBySpaceResponse {
  spaceId: Space["spaceId"]
  switches: Array<BooleanFeatureSwitch<boolean> | OtherFeatureSwitch>
}

export interface BooleanFeatureSwitchDeleteRequest
  extends Pick<BooleanFeatureSwitch<boolean>, "switchName"> {
  spaceId: Space["spaceId"]
  qdOperationReason?: OperationReason["qdOperationReason"]
}
