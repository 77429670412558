import { Link, LinkProps } from "@mui/joy"
import React, { forwardRef, PropsWithChildren, useMemo } from "react"
import { NavLinkProps, NavLink, useLocation } from "react-router-dom"

interface Props
  extends Omit<NavLinkProps, "children" | "color" | "className" | "style">,
    LinkProps,
    PropsWithChildren {}

/* This component renders while preserving search params */
const ContextLinkComponent = (
  { children, to, ...props }: Props,
  ref: React.Ref<HTMLAnchorElement>
) => {
  const { search } = useLocation()

  const adjustedTo = useMemo(() => {
    const params = new URLSearchParams(search)
    for (const p of params.keys()) {
      if (p !== "env" && p !== "gameId" && p !== "spaceId") {
        params.delete(p)
      }
    }
    const sanitizedParams = params.size ? `?${params?.toString()}` : ""

    return typeof to === "string"
      ? to + sanitizedParams
      : {
          ...to,
          search: to.search
            ? `${to.search}&${params?.toString()}`
            : sanitizedParams
        }
  }, [search, to])

  return (
    <Link {...props} component={NavLink} to={adjustedTo} ref={ref}>
      {children}
    </Link>
  )
}

export const ContextLink = forwardRef(ContextLinkComponent)
