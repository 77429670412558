import { Typography, Card, CardContent } from "@mui/joy"
import { Unstable_Grid2 as Grid } from "@mui/material"
import React from "react"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { useAppSelector } from "@/hooks/store"
import { useGetAllApplicationsByGameIdQuery } from "@/store/api/application"
import { selectEnv, selectGame } from "@/store/slices/context"

export const OverviewApplications: React.FC = () => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const { data: applications } = useGetAllApplicationsByGameIdQuery(
    { gameId, env },
    { skip: !gameId || !env }
  )
  return (
    <>
      <Typography level="h4" mb={1}>
        Associated applications
      </Typography>

      {applications?.length ? (
        <Grid container spacing={1} columns={12}>
          {applications?.map(({ appId, name }) => {
            return (
              <Grid xs={6} sm={4} md={3} key={appId}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography level="title-md">{name}</Typography>
                    <Typography level="body-sm">
                      <CopyToClipboard>{appId}</CopyToClipboard>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Typography>{`No associated application found.`}</Typography>
      )}
    </>
  )
}
