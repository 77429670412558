import * as Sentry from "@sentry/react"
import { Outlet, Route, Routes as RouterRoutes } from "react-router-dom"
import { Layout } from "@/components/layout/layout"
import { NAVIGATION } from "@/constants/navigation"

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(RouterRoutes)

export const Routes = () => {
  return (
    <SentryRoutes>
      {NAVIGATION.map((section) => (
        <Route key={section.id} element={<Outlet />}>
          {section.items.map((item) =>
            item.pages ? (
              <Route key={item.id} path={item.id} element={<Outlet />}>
                {item.pages?.map((page) => (
                  <Route
                    index={page.index}
                    key={page.id}
                    path={!page.index ? page.id : undefined}
                    element={<Layout {...page} />}
                  />
                ))}
              </Route>
            ) : (
              <Route
                index={item.index}
                key={item.id}
                path={!item.index ? item.id : undefined}
                element={<Layout {...item} />}
              />
            )
          )}
        </Route>
      ))}

      {/* <Route path="forbidden" element={<ErrorPage />} />
        <Route path="not-found" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} /> */}
    </SentryRoutes>
  )
}
