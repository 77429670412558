import {
  Challenge,
  insertIf,
  Operation,
  SeasonComponentItem
} from "@liveops-portal/lib"
import {
  List,
  ListItem,
  ListItemButton,
  listItemButtonClasses,
  Stack,
  Typography
} from "@mui/joy"
import { Collapse } from "@mui/material"
import { NavArrowDown } from "iconoir-react"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { CardItem } from "@/components/card-item/card-item"
import { ChallengeUpdate } from "@/components/challenge-update/challenge-update"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useGetGameDataBySeasonIdQuery } from "@/store/api/game-data"
import {
  useDeleteSeasonComponentItemMutation,
  useGetSeasonComponentItemsQuery
} from "@/store/api/season"
import { selectActiveOperation, setActiveOperation } from "@/store/slices/audit"
import { selectEnv, selectGame, selectSeason } from "@/store/slices/context"

interface Props {
  itemId: SeasonComponentItem["itemId"]
}

export const ChallengeCard: React.FC<Props> = ({ itemId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const activeOperation = useAppSelector(selectActiveOperation)
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)
  const skip = { skip: !seasonId || !gameId || !env }

  // DATA
  const [expandLevels, setExpandLevels] = useState<boolean>(false)
  const { data: filters } = useGetGameDataBySeasonIdQuery(
    { gameId, seasonId, env, dataTypes: ["challenge-filters"] },
    skip
  )
  const { item } = useGetSeasonComponentItemsQuery(
    { gameId, seasonId, env, componentType: "challenge" },
    {
      ...skip,
      selectFromResult: ({ data }) => ({
        item: data?.find((item) => item.itemId === itemId) as
          | SeasonComponentItem<Challenge>
          | undefined
      })
    }
  )
  const { displayName, scope, progressType, levels, progressInMenu, filter } =
    item || {}
  const _filter = useMemo(
    () =>
      /* c8 ignore next */
      filters?.["challenge-filters"]?.find(({ guid }) => guid === filter) || {},
    [filter, filters]
  )

  // UPDATE
  const [openUpdate, setOpenUpdate] = useState<boolean>(false)

  // DELETE
  const [deleteChallenge, deleteChallengeMeta] =
    useDeleteSeasonComponentItemMutation()
  const deleteOperation: Operation = [itemId, "delete"]
  const isDeleteOperationActive = isOperationActive(
    activeOperation,
    deleteOperation
  )
  const isLoadingDelete =
    isDeleteOperationActive || deleteChallengeMeta.isLoading

  const onSubmitHandler = () => {
    if (isDeleteOperationActive)
      deleteChallenge({ seasonId, gameId, itemId, componentType: "challenge" })
  }

  return (
    item && (
      <>
        <OperationReason
          open={isDeleteOperationActive}
          onSubmit={onSubmitHandler}
          autofocus
          closeable
        />

        <ChallengeUpdate open={openUpdate} setOpen={setOpenUpdate} {...item} />

        <CardItem
          title={{ content: displayName }}
          active={isLoadingDelete}
          chips={[
            {
              content: scope!
            },
            {
              content: progressType!
            },
            ...insertIf(!!_filter, {
              content: _filter.name as string
            })
          ]}
          subtitles={[
            { content: itemId, copiable: true },
            {
              content: (
                <List
                  size="sm"
                  variant="plain"
                  aria-expanded={expandLevels}
                  sx={({ spacing }) => ({
                    "--ListItem-paddingY": 0,
                    "--ListItem-minHeight": 0,
                    "--ListDivider-gap": 0,
                    "--ListItem-startActionWidth": spacing(1),
                    "--ListItem-startActionTranslateX": spacing(-0.5),
                    fontWeight: "sm"
                  })}
                >
                  <ListItem
                    startAction={
                      <NavArrowDown
                        style={{
                          transform: expandLevels ? "none" : "rotate(-90deg)",
                          transition: "all 0.2s"
                        }}
                      />
                    }
                    onClick={() => {
                      setExpandLevels(!expandLevels)
                    }}
                  >
                    <ListItemButton
                      sx={{
                        color: "text.tertiary",
                        [`&.${listItemButtonClasses.root}:hover`]: {
                          bgcolor: "transparent"
                        }
                      }}
                    >
                      <Typography fontWeight="md">
                        {t("_seasonDesigner.challenges.levels")}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={expandLevels} timeout={200}>
                    <List
                      marker="decimal"
                      sx={({ spacing }) => ({
                        "--ListItem-paddingY": 0,
                        "--ListDivider-gap": 0,
                        "--ListItem-minHeight": spacing(0.5),
                        fontSize: "xs",
                        ml: 1
                      })}
                    >
                      {levels?.map(({ threshold, reward }, index) => (
                        <ListItem key={`challenge-level-${index}`}>
                          <Stack direction="row" gap={1}>
                            <Typography>
                              <Typography fontWeight="md">
                                {t("_seasonDesigner.challenges.threshold")}:{" "}
                              </Typography>
                              <Typography textColor="text.tertiary">
                                {threshold}
                              </Typography>
                            </Typography>
                            <Typography>
                              <Typography fontWeight="md">
                                {t("_seasonDesigner.challenges.reward")}:{" "}
                              </Typography>
                              <Typography textColor="text.tertiary">
                                {reward}
                              </Typography>
                            </Typography>
                          </Stack>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </List>
              )
            }
          ]}
          sections={[
            {
              title: t("_seasonDesigner.challenges.progressInMenu"),
              content: progressInMenu ? "Yes" : "No"
            }
          ]}
          actions={{
            update: {
              handler: () => {
                setOpenUpdate(true)
              }
            },
            delete: {
              handler: () => {
                dispatch(setActiveOperation(deleteOperation))
              }
            }
          }}
        />
      </>
    )
  )
}
