import { TAB_IDS } from "@liveops-portal/lib"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { INITIAL_TAB_INDEX } from "@/constants/tab"
import { GlobalState } from "@/typings/redux"

/* Interface for the state of the Tab feature. */
export interface TabState {
  activeTabIndexes: Record<string, number>
}

const initialActiveTabIndexes = Object.values(TAB_IDS).reduce(
  (activeTabIndexes: TabState["activeTabIndexes"], tabId: string) => {
    activeTabIndexes[tabId] = INITIAL_TAB_INDEX

    return activeTabIndexes
  },
  {}
)

/* Redux store slice for the Tab feature. */
export const tabSlice = createSlice({
  name: "tab",
  initialState: {
    activeTabIndexes: initialActiveTabIndexes
  } as TabState,
  reducers: {
    resetActiveTabIndex: (
      state: TabState,
      { payload: tabName }: PayloadAction<string>
    ) => {
      state.activeTabIndexes[tabName] = INITIAL_TAB_INDEX
    },
    setActiveTabIndex: (
      state: TabState,
      {
        payload: { tabName, activeTabIndex }
      }: PayloadAction<{ tabName: string; activeTabIndex: number }>
    ) => {
      state.activeTabIndexes[tabName] =
        state.activeTabIndexes[tabName] !== activeTabIndex
          ? activeTabIndex
          : INITIAL_TAB_INDEX
    }
  }
})

/* Actions related to the Tab feature. */
export const { setActiveTabIndex, resetActiveTabIndex } = tabSlice.actions

/* Selectors related to the Tab feature. */
export const selectActiveTabIndexByTabName =
  (tabName: string) => (state: GlobalState) =>
    state.tab.activeTabIndexes[tabName]

export default tabSlice.reducer
