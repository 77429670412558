import { ByEnvQuery, ByGameIdQuery, DataState, Game } from "."

export enum GameServerBuildDeploymentType {
  DOCKER = "Docker",
  ZIP = "ZIP"
}

export enum GameServerBuildType {
  debug = "Debug",
  profile = "Profile",
  release = "Release",
  retail = "Retail",
  unknown = "Unknown"
}

export interface GameServerBuild {
  gameId: Game["gameId"]
  buildId: string
  buildType: keyof typeof GameServerBuildType
  codeChangeList: string | null
  dataChangeList: string | null
  deploymentType: keyof typeof GameServerBuildDeploymentType
  state: keyof typeof DataState
  lastUpdate: string
  buildName: string
  uploadPath: string
  preserved: boolean
  deployments?: GameServerBuildDeployment[]
}

export enum GameServerDeploymentState {
  InProgress = "In Progress",
  Deployed = "Deployed"
}
export interface GameServerBuildDeployment {
  spaceId: string
  buildId: string
  numberOfCores: number
  cpuType: string
  state: keyof typeof GameServerDeploymentState
}

export type AllGameServerBuildsQuery = ByGameIdQuery & ByEnvQuery
