import {
  GameServerBuild,
  GameServerBuildDeploymentType,
  DataState,
  GameServerBuildType
} from "@liveops-portal/lib"
import { Stack, Typography } from "@mui/joy"
import React, { useMemo } from "react"
import { Filters } from "@/components/filters/filters"
import { GameServerBuildCard } from "@/components/game-server-build-card/game-server-build-card"
import { useAppSelector } from "@/hooks/store"
import { useGetGameServerBuildsQuery } from "@/store/api/game-server-build"
import { selectEnv, selectGame } from "@/store/slices/context"
import { selectFilteringResults } from "@/store/slices/filtering"

/**
 * Component representing the build page.
 */
export const GameServerBuildsPage: React.FC = () => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const filteredGameServerBuilds = useAppSelector(selectFilteringResults)

  const {
    data: gameServerBuilds,
    isUninitialized,
    isFetching
  } = useGetGameServerBuildsQuery({ gameId, env }, { skip: !gameId || !env })

  // TODO: Memo shouldn't be used here 🙃
  const searchable = useMemo(
    () => ["buildName", "buildId", "codeChangeList", "dataChangeList"],
    []
  )

  const filters = [
    {
      field: "buildType" as const,
      label: "Build type",
      options: GameServerBuildType
    },
    {
      field: "deploymentType" as const,
      label: "Deployment type",
      options: GameServerBuildDeploymentType
    },
    {
      field: "state" as const,
      label: "Build state",
      options: DataState
    }
  ]

  return isUninitialized ? (
    <Typography>Please choose a game to view the associated builds.</Typography>
  ) : isFetching ? (
    <Typography>Loading...</Typography>
  ) : gameServerBuilds?.length ? (
    <Stack gap={2}>
      <Filters
        items={gameServerBuilds}
        searchable={searchable}
        filters={filters}
      />
      {filteredGameServerBuilds.length ? (
        <Stack gap={2}>
          {filteredGameServerBuilds.map((build: GameServerBuild) => (
            <GameServerBuildCard key={build.buildId} {...build} />
          ))}
        </Stack>
      ) : (
        <Typography>No builds found for the selected criteria.</Typography>
      )}
    </Stack>
  ) : (
    <Typography>No builds found for selected game.</Typography>
  )
}
