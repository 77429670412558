import { NavSection } from "@liveops-portal/lib"
import {
  CodeBrackets,
  Community,
  CubeHole,
  FireFlame,
  Gamepad,
  Home,
  Megaphone,
  PharmacyCrossCircle,
  Server,
  Tournament,
  Trophy,
  Wrench
} from "iconoir-react"
import { ApplicationsPage } from "@/pages/applications-page/applications-page"
import { AuditPage } from "@/pages/audit-page/audit-page"
import { ConfigPage } from "@/pages/config-page/config-page"
import { FeatureSwitchesPage } from "@/pages/feature-switches-page/feature-switches-page"
import { GameServerBuildsPage } from "@/pages/game-server-builds-page/game-server-builds-page"
import { HealthchecksPage } from "@/pages/healthchecks-page/healthchecks-page"
import { OverviewPage } from "@/pages/overview-page/overview-page"
import { SeasonDesignerBattlepassPage } from "@/pages/season-designer-battlepass-page/season-designer-battlepass-page"
import { SeasonDesignerChallengesPage } from "@/pages/season-designer-challenges-page/season-designer-challenges-page"
import { SeasonDesignerDataViewerPage } from "@/pages/season-designer-data-viewer-page/season-designer-data-viewer-page"
import { SeasonDesignerOverviewPage } from "@/pages/season-designer-overview-page/season-designer-overview-page"
import { ToolboxPage } from "@/pages/toolbox-page/toolbox.page"

export const NAVIGATION: NavSection[] = [
  {
    id: "general",
    name: "General",
    items: [
      {
        name: "Overview",
        id: "overview",
        icon: Home,
        context: ["env"],
        element: OverviewPage,
        index: true
      },
      {
        name: "Healthchecks",
        id: "healthchecks",
        icon: Server,
        context: ["env"],
        element: HealthchecksPage
      },
      {
        name: "Toolbox",
        id: "toolbox",
        icon: CodeBrackets,
        context: ["space"],
        element: ToolboxPage
      },
      {
        name: "Audit",
        id: "audit",
        icon: Megaphone,
        context: ["env"],
        element: AuditPage
      }
    ]
  },
  {
    id: "game",
    name: "Game",
    items: [
      {
        name: "Gameserver Builds",
        id: "game-server-builds",
        icon: CubeHole,
        context: ["game"],
        element: GameServerBuildsPage
      },
      {
        name: "Applications",
        id: "applications",
        icon: Gamepad,
        context: ["game"],
        element: ApplicationsPage
      },
      {
        name: "Season Designer",
        id: "season-designer",
        icon: Tournament,
        context: ["game"],
        pages: [
          {
            id: "overview",
            name: "Overview",
            title: "Season Designer",
            context: ["game"],
            element: SeasonDesignerOverviewPage,
            index: true
          },
          {
            name: "Timeline",
            id: "timeline",
            context: ["game"],
            disabled: true
          },
          {
            name: "Data Viewer",
            id: "data-viewer",
            element: SeasonDesignerDataViewerPage,
            context: ["game", "season"]
          },
          {
            name: "Battlepass",
            id: "battlepass",
            element: SeasonDesignerBattlepassPage,
            context: ["game", "season"]
          },
          {
            name: "Challenges",
            id: "challenges",
            element: SeasonDesignerChallengesPage,
            context: ["game", "season"]
          },
          {
            name: "Community Votes",
            id: "community-votes",
            context: ["game", "season"],
            disabled: true
          },
          {
            name: "Shop",
            id: "shop",
            context: ["game", "season"],
            disabled: true
          },
          {
            name: "News",
            id: "news",
            context: ["game", "season"],
            disabled: true
          }
        ]
      }
    ]
  },
  {
    id: "space",
    name: "Space",
    items: [
      {
        name: "Health",
        id: "health",
        icon: PharmacyCrossCircle,
        context: ["space"],
        disabled: true,
        pages: [
          {
            name: "News",
            id: "news",
            context: ["space"],
            disabled: true
          },
          {
            name: "Feature Switches",
            id: "feature-switches",
            element: FeatureSwitchesPage,
            context: ["space"]
          },
          {
            name: "Remote Logs",
            id: "remote-logs",
            context: ["space"],
            disabled: true
          }
        ]
      },
      {
        name: "Configurations",
        id: "config",
        icon: Wrench,
        context: ["space"],
        element: ConfigPage
      },
      {
        name: "LiveOps",
        id: "liveops",
        icon: FireFlame,
        context: ["space"],
        disabled: true,
        pages: [
          {
            name: "Playlists",
            id: "playlists",
            context: ["space"],
            index: true,
            disabled: true
          },
          {
            name: "Live Balancing",
            id: "live-balancing",
            context: ["space"],
            disabled: true
          },
          {
            name: "Telemetry",
            id: "telemetry",
            context: ["space"],
            disabled: true
          }
        ]
      },
      {
        name: "Seasons",
        id: "seasons",
        icon: Trophy,
        context: ["space"],
        disabled: true,
        pages: [
          {
            name: "Management",
            id: "management",
            context: ["space"],
            index: true,
            disabled: true
          },
          {
            name: "Timeline",
            id: "timeline",
            context: ["space"],
            disabled: true
          },
          {
            name: "Data Viewer",
            id: "data-viewer",
            context: ["space"],
            disabled: true
          },
          {
            name: "Battlepass",
            id: "battlepass",
            context: ["space"],
            disabled: true
          },
          {
            name: "Challenges",
            id: "challenges",
            context: ["space"],
            disabled: true
          },
          {
            name: "Community Votes",
            id: "community-votes",
            context: ["space"],
            disabled: true
          },
          {
            name: "Shop",
            id: "shop",
            context: ["space"],
            disabled: true
          },
          {
            name: "News",
            id: "news",
            context: ["space"],
            disabled: true
          }
        ]
      },
      {
        name: "Players",
        id: "players",
        icon: Community,
        context: ["space"],
        disabled: true,
        pages: [
          {
            name: "Player Finder",
            id: "player-finder",
            context: ["space"],
            index: true,
            disabled: true
          },
          {
            name: "Mass Grant",
            id: "mass-grant",
            context: ["space"],
            disabled: true
          },
          {
            name: "Player Reports",
            id: "player-reports",
            context: ["space"],
            disabled: true
          }
        ]
      }
    ]
  }
]
