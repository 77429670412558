import {
  BuildSpaceAssociationsByAppId,
  ByEnvQuery,
  ByGameIdQuery
} from "@liveops-portal/lib"
import { api } from "@/store/api"

export const buildApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllBuildsByGameId: builder.query<
      BuildSpaceAssociationsByAppId,
      ByGameIdQuery & ByEnvQuery
    >({
      query: ({ gameId }) => ({
        url: "builds",
        params: { gameId }
      })
    })
  }),
  overrideExisting: false
})

export const { useGetAllBuildsByGameIdQuery } = buildApi
