import {
  AllApplicationsByGameQuery,
  Application,
  ByEnvQuery
} from "@liveops-portal/lib"
import { api } from "@/store/api"

export const applicationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllApplicationsByGameId: builder.query<
      Application[],
      AllApplicationsByGameQuery & ByEnvQuery
    >({
      query: ({ gameId, includeMarkedForDeletion }) => ({
        url: `applications`,
        params: { gameId, includeMarkedForDeletion }
      })
    })
  }),
  overrideExisting: false
})

export const { useGetAllApplicationsByGameIdQuery } = applicationApi
