import { Stack, Typography } from "@mui/joy"
import React from "react"
import { useTranslation } from "react-i18next"
import { SeasonCard } from "@/components/season-card/season-card"
import { SeasonCreate } from "@/components/season-create/season-create"
import { useAppSelector } from "@/hooks/store"
import { usePageActions } from "@/hooks/usePageActions"
import { useGetSeasonsQuery } from "@/store/api/season"
import { selectEnv, selectGame } from "@/store/slices/context"

/**
 * Component representing the Season Designer Overview page.
 */
export const SeasonDesignerOverviewPage: React.FC = () => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const { t } = useTranslation()

  const {
    data: seasons,
    isUninitialized,
    isFetching
  } = useGetSeasonsQuery({ gameId, env }, { skip: !gameId || !env })

  usePageActions([<SeasonCreate key="season-create" />])

  return isUninitialized ? (
    <Typography>{t("_seasonDesigner.overview.uninitialized")}</Typography>
  ) : isFetching ? (
    <Typography>{t("_common.loading")}</Typography>
  ) : seasons?.length ? (
    <Stack gap={2}>
      {seasons.map(({ seasonId }) => (
        <SeasonCard key={seasonId} seasonId={seasonId} />
      ))}
    </Stack>
  ) : (
    <Typography>{t("_seasonDesigner.overview.noItems")}</Typography>
  )
}
