import {
  Select,
  Option,
  Input,
  IconButton,
  Stack,
  SelectProps,
  InputProps
} from "@mui/joy"
import { SxProps } from "@mui/system"
import { Plus, Xmark } from "iconoir-react"
import React, { Dispatch, SetStateAction, useState } from "react"

interface Props {
  name: string
  options: string[]
  type: string
  value: string | null
  setValue: Dispatch<SetStateAction<string | null>>
  placeholder?: string
  sx?: SxProps
  onSelectChange?: SelectProps<string, false>["onChange"]
  onInputChange?: InputProps["onChange"]
}

export const SelectWithAdd: React.FC<Props> = ({
  name,
  options,
  type,
  value,
  setValue,
  placeholder,
  onSelectChange,
  onInputChange,
  sx
}: Props) => {
  const [isAdding, setIsAdding] = useState<boolean | undefined>()

  return (
    <Stack direction="row" gap={1} sx={sx}>
      {isAdding ? (
        <Input
          autoFocus
          fullWidth
          name={name}
          placeholder={placeholder || `New ${type} name`}
          onChange={(event) => {
            setValue(event?.target.value)
            onInputChange?.(event)
          }}
          type="text"
        />
      ) : (
        <Select
          value={value}
          name={name}
          onChange={(event, value) => {
            setValue(value)
            onSelectChange?.(event, value)
          }}
          sx={{ flexGrow: 1 }}
        >
          {options?.map((option) => (
            <Option key={option} label={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      )}
      <IconButton
        size="sm"
        variant="outlined"
        title={isAdding ? "cancel" : `add a new ${type}`}
        onClick={() => {
          isAdding ? setValue(options[0]) : setValue(null)
          setIsAdding(!isAdding)
        }}
      >
        {isAdding ? <Xmark /> : <Plus />}
      </IconButton>
    </Stack>
  )
}
