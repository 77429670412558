import {
  AutocompleteOption,
  Autocomplete,
  createFilterOptions,
  ListItemContent,
  Typography,
  Chip
} from "@mui/joy"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useGetAllGamesQuery } from "@/store/api/game"
import { useGetAllSpacesQuery } from "@/store/api/space"
import {
  selectContext,
  selectEnv,
  selectSpace,
  setSpaceId
} from "@/store/slices/context"

export const SpaceSelector: React.FC = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const env = useAppSelector(selectEnv)
  const context = useAppSelector(selectContext)
  const spaceId = useAppSelector(selectSpace)
  const spaceParam = useMemo(() => searchParams.get("spaceId"), [searchParams])
  const spaceInit = useRef(false)

  const { data: spaces, isFetching: isFetchingSpaces } = useGetAllSpacesQuery(
    { env },
    { skip: !env }
  )
  const { data: games, isFetching: isFetchingGames } = useGetAllGamesQuery(
    { env },
    { skip: !env }
  )
  const isFetching = isFetchingSpaces || isFetchingGames

  const options = spaces
    ?.map((space) => ({
      ...space,
      gameName: games?.find(({ gameId }) => gameId === space.gameId)?.name
    }))
    .sort(
      (a, b) =>
        a.gameId.localeCompare(b.gameId) ||
        a.spaceName.localeCompare(b.spaceName)
    )

  const value = useMemo(
    () => spaces?.find((space) => space.spaceId === spaceId),
    [spaceId, spaces]
  )

  const initializeSpace = useCallback(() => {
    spaceParam != null && dispatch(setSpaceId(spaceParam))
  }, [dispatch, spaceParam])

  useEffect(() => {
    if (!spaceInit.current) {
      initializeSpace()
      spaceInit.current = true
    }
  }, [initializeSpace])

  return (
    <Autocomplete
      autoComplete
      id="space-selector"
      placeholder={isFetching ? "Loading..." : "Select a space"}
      disabled={isFetching}
      sx={{
        position: "absolute",
        inset: 0,
        opacity: Number(context.includes("space")),
        width: 260,
        pointerEvents: context.includes("space") ? "all" : "none"
      }}
      value={value || null}
      startDecorator={spaceId ? <Chip color="space">Space</Chip> : null}
      onChange={(_, _value) =>
        _value != null && dispatch(setSpaceId(_value?.spaceId))
      }
      options={options || []}
      groupBy={({ gameName }) => gameName!}
      filterOptions={createFilterOptions({
        stringify: (option) => `${option.spaceName} ${option.spaceId}`
      })}
      isOptionEqualToValue={(option, value) => option.spaceId === value.spaceId}
      getOptionLabel={(option) => option.spaceName}
      renderOption={(props, option) => (
        <AutocompleteOption {...props} key={option.spaceId}>
          <ListItemContent>
            <Typography level="body-sm" textColor="text.primary">
              {option.spaceName}
            </Typography>
            <Typography level="body-xs" fontWeight="400" noWrap>
              {option.spaceId}
            </Typography>
          </ListItemContent>
        </AutocompleteOption>
      )}
    />
  )
}
