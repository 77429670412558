import { ByEnvQuery, Space } from "@liveops-portal/lib"
import { api } from "@/store/api"

export const spaceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllSpaces: builder.query<Space[], ByEnvQuery>({
      query: () => ({
        url: `spaces`
      })
    })
  }),
  overrideExisting: false
})

export const { useGetAllSpacesQuery, useLazyGetAllSpacesQuery } = spaceApi
