import { BooleanFeatureSwitch } from "@liveops-portal/lib"
import { Grid, Stack, Typography } from "@mui/joy"
import React from "react"
import { BooleanFeatureSwitchCard } from "@/components/boolean-feature-switch-card/boolean-feature-switch-card"
import { OtherFeatureSwitchCard } from "@/components/other-feature-switch-card/other-feature-switch-card"
import { useAppSelector } from "@/hooks/store"
import { useGetAllFeatureSwitchesQuery } from "@/store/api/feature-switch"
import { selectEnv, selectSpace } from "@/store/slices/context"

export const FeatureSwitchesPage: React.FC = () => {
  const env = useAppSelector(selectEnv)
  const spaceId = useAppSelector(selectSpace)

  const skip = !env || !spaceId

  const { data, isFetching } = useGetAllFeatureSwitchesQuery(
    { spaceId },
    { skip }
  )

  return skip ? (
    <Typography>Please select a space to access feature switches.</Typography>
  ) : isFetching ? (
    <Typography>Loading...</Typography>
  ) : (
    <Stack gap={2}>
      <Grid container spacing={2} columns={12}>
        {data?.switches?.length ? (
          data?.switches.map((featureSwitch, index) =>
            typeof featureSwitch.switchValue === "boolean" ? (
              <BooleanFeatureSwitchCard
                key={index}
                {...(featureSwitch as BooleanFeatureSwitch<boolean>)}
              />
            ) : (
              <OtherFeatureSwitchCard key={index} {...featureSwitch} />
            )
          )
        ) : (
          <Typography>This space has no feature switches.</Typography>
        )}
      </Grid>
    </Stack>
  )
}
