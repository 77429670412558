import { Buffer } from "buffer"
import { GRAPH_API_URL } from "@/constants/auth"
import { api } from "@/store/api"

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPhoto: builder.query<
      string | undefined,
      { id: string | undefined; size?: number }
    >({
      query: () => ({
        url: `${GRAPH_API_URL}/photo/$value`,
        cache: "no-cache",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responseHandler: (response: any) => {
          /* c8 ignore start */
          if (response.status !== 200) {
            return { error: response.statusText, status: response.status }
          }
          /* c8 ignore end */
          return response.blob()
        }
      }),
      transformResponse: async (response: Blob) => {
        if (response.constructor.name === "Blob") {
          const buffer = Buffer.from(await response.arrayBuffer())
          return (
            "data:" + response.type + ";base64," + buffer.toString("base64")
          )
        }
      }
    })
  })
})

export const { useGetPhotoQuery } = userApi
