import {
  AllSeasonsQuery,
  Season,
  SeasonComponentItemCreateRequest,
  SeasonComponentItemCreateResponse,
  SeasonCreateRequest,
  SeasonCreateResponse,
  SeasonDeleteRequest,
  SeasonUpdateRequest,
  SeasonUpdateResponse,
  SeasonPublishRequest,
  formatWeeksToTimeSpan,
  SeasonComponentItemUpdateRequest,
  SeasonComponentItemUpdateResponse,
  SeasonComponentItemDeleteRequest,
  SeasonComponentItem,
  SeasonComponentItemsByTypeQuery
} from "@liveops-portal/lib"
import { capitalize } from "@mui/material"
import { v4 as uuidv4 } from "uuid"
import { getError } from "@/helpers/errors"
import notify from "@/helpers/notify"
import { api } from "@/store/api"

export const seasonApi = api
  .enhanceEndpoints({
    addTagTypes: ["Season", "SeasonComponentItem"]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSeasons: builder.query<Season[], AllSeasonsQuery>({
        query: ({ gameId }) => ({
          url: "seasons",
          params: { gameId }
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ seasonId }) => ({
                  type: "Season" as const,
                  id: seasonId
                })),

                { type: "Season", id: "ALL" }
              ]
            : [{ type: "Season", id: "ALL" }],
        onQueryStarted({ gameId }, { queryFulfilled }) {
          queryFulfilled.catch((err) =>
            notify.error(
              `Could not get seasons for game ${gameId}. ${getError(err)}`
            )
          )
        }
      }),
      createSeason: builder.mutation<SeasonCreateResponse, SeasonCreateRequest>(
        {
          query: ({
            gameId,
            seasonName,
            seasonRevisionDetails: { duration, clientDataVersion },
            qdOperationReason
          }) => {
            const seasonId = uuidv4()

            return {
              url: `seasons/${seasonId}`,
              method: "POST",
              params: { gameId },
              body: {
                seasonName,
                seasonRevisionDetails: {
                  clientDataVersion,
                  duration: formatWeeksToTimeSpan(duration)
                }
              },
              headers: {
                "QD-Operation-Reason": qdOperationReason
              }
            }
          },
          invalidatesTags: [{ type: "Season", id: "ALL" }],
          onQueryStarted(_, { queryFulfilled }) {
            queryFulfilled
              .then(() => {
                notify.success(`Season created successfully.`)
              })
              .catch((err) => {
                notify.error(`Season could not be created. ${getError(err)}`)
              })
          }
        }
      ),
      deleteSeason: builder.mutation<void, SeasonDeleteRequest>({
        query: ({ seasonId, gameId }) => ({
          url: `seasons/${seasonId}`,
          params: { gameId },
          method: "DELETE"
        }),
        invalidatesTags: (result, error, { seasonId }) => [
          { type: "Season", id: seasonId }
        ],
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(`Season was successfully deleted.`)
            })
            .catch((err) => {
              notify.error(`Season could not be deleted. ${getError(err)}`)
            })
        }
      }),
      updateSeason: builder.mutation<SeasonUpdateResponse, SeasonUpdateRequest>(
        {
          query: ({
            gameId,
            seasonId,
            seasonRevisionDetails,
            qdOperationReason
          }) => ({
            url: `seasons/${seasonId}`,
            method: "PUT",
            params: { gameId },
            body: {
              clientDataVersion: seasonRevisionDetails?.clientDataVersion,
              duration:
                seasonRevisionDetails &&
                formatWeeksToTimeSpan(seasonRevisionDetails.duration)
            },
            headers: {
              "QD-Operation-Reason": qdOperationReason
            }
          }),
          invalidatesTags: (result, error, { seasonId }) => [
            { type: "Season", id: seasonId }
          ],
          onQueryStarted(_, { queryFulfilled }) {
            queryFulfilled
              .then(() => {
                notify.success(`Season updated successfully.`)
              })
              .catch((err) => {
                notify.error(`Season could not be updated. ${getError(err)}`)
              })
          }
        }
      ),
      publishSeasonRevision: builder.mutation<void, SeasonPublishRequest>({
        query: ({ seasonId, gameId, revision }) => ({
          url: `seasons/${seasonId}/publish`,
          method: "POST",
          params: { gameId },
          body: { revision }
        }),
        invalidatesTags: (result, error, { seasonId }) => [
          { type: "Season", id: seasonId }
        ],
        onQueryStarted({ seasonId, revision }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                `Revision ${revision} for season ${seasonId} was successfully published.`
              )
            })
            .catch((err) => {
              notify.error(
                `Revision ${revision} for season ${seasonId} could not be published. ${getError(err)}`
              )
            })
        }
      }),
      getSeasonComponentItems: builder.query<
        SeasonComponentItem[],
        SeasonComponentItemsByTypeQuery
      >({
        query: ({ gameId, seasonId, componentType }) => ({
          url: `seasons/${seasonId}/components/${componentType}`,
          params: { gameId }
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ itemId }) => ({
                  type: "SeasonComponentItem" as const,
                  id: itemId
                })),

                { type: "SeasonComponentItem", id: "ALL" }
              ]
            : [{ type: "SeasonComponentItem", id: "ALL" }],
        onQueryStarted({ seasonId, componentType }, { queryFulfilled }) {
          queryFulfilled.catch((err) =>
            notify.error(
              `Could not get ${componentType} items for season ${seasonId}. ${getError(err)}`
            )
          )
        }
      }),
      createSeasonComponentItem: builder.mutation<
        SeasonComponentItemCreateResponse,
        SeasonComponentItemCreateRequest
      >({
        query: ({
          gameId,
          seasonId,
          qdOperationReason,
          componentType,
          ...body
        }) => {
          const itemId = uuidv4()
          return {
            url: `seasons/${seasonId}/components/${componentType}/items/${itemId}`,
            method: "POST",
            params: { gameId },
            body,
            headers: {
              "QD-Operation-Reason": qdOperationReason
            }
          }
        },
        invalidatesTags: (result, error, { itemId }) => [
          { type: "SeasonComponentItem", id: itemId }
        ],
        onQueryStarted({ displayName, componentType }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                `${capitalize(componentType)} ${displayName} created successfully.`
              )
            })
            .catch((err) => {
              notify.error(
                `${capitalize(componentType)} could not be created. ${getError(err)}`
              )
            })
        }
      }),
      updateSeasonComponentItem: builder.mutation<
        SeasonComponentItemUpdateResponse,
        SeasonComponentItemUpdateRequest
      >({
        query: ({
          gameId,
          seasonId,
          itemId,
          componentType,
          qdOperationReason,
          ...body
        }) => ({
          url: `seasons/${seasonId}/components/${componentType}/items/${itemId}`,
          method: "PUT",
          params: { gameId },
          body,
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { itemId }) => [
          { type: "SeasonComponentItem", id: itemId }
        ],
        onQueryStarted({ displayName, componentType }, { queryFulfilled }) {
          queryFulfilled
            .then(({ data: result }) => {
              notify.success(
                `${capitalize(componentType)} ${result.displayName} updated successfully.`
              )
            })
            .catch((err) => {
              notify.error(
                `${capitalize(componentType)} ${displayName} could not be updated. ${getError(err)}`
              )
            })
        }
      }),
      deleteSeasonComponentItem: builder.mutation<
        void,
        SeasonComponentItemDeleteRequest
      >({
        query: ({
          gameId,
          seasonId,
          itemId,
          componentType,
          qdOperationReason
        }) => ({
          url: `seasons/${seasonId}/components/${componentType}/items/${itemId}`,
          method: "DELETE",
          params: { gameId },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { itemId }) => [
          { type: "SeasonComponentItem", id: itemId }
        ],
        onQueryStarted({ componentType }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                `${capitalize(componentType)} successfully deleted.`
              )
            })
            .catch((err) => {
              notify.error(
                `${capitalize(componentType)} could not be deleted. ${getError(err)}`
              )
            })
        }
      })
    }),
    overrideExisting: false
  })

export const {
  useCreateSeasonComponentItemMutation,
  useUpdateSeasonComponentItemMutation,
  useDeleteSeasonComponentItemMutation,
  useCreateSeasonMutation,
  useDeleteSeasonMutation,
  usePublishSeasonRevisionMutation,
  useUpdateSeasonMutation,
  useGetSeasonsQuery,
  useGetSeasonComponentItemsQuery
} = seasonApi
