import { useMsal } from "@azure/msal-react"
import { Box, Button, Stack, Typography } from "@mui/joy"
import { Helmet } from "react-helmet"
import backgroundImg from "@/assets/login-bg.jpeg"
import Microsoft from "@/assets/microsoft.svg?react"
import { Logo } from "@/components/logo/logo"
import { ModeSwitcher } from "@/components/mode-switcher/mode-switcher"
import { MSAL_LOGIN_REQUEST } from "@/constants/auth"

export const LoginPage: React.FC = () => {
  const { instance } = useMsal()

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box width="50vw">
        <Stack minHeight="100dvh" px={1.5} py={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            component="header"
          >
            <Logo />
            <ModeSwitcher />
          </Stack>
          <Stack component="main" m="auto" width={400}>
            <Stack gap={4} mb={2}>
              <Typography component="h1" level="h3" textAlign="center">
                Sign in
              </Typography>
              <Button
                variant="soft"
                color="neutral"
                fullWidth
                onClick={() => {
                  instance.loginRedirect(MSAL_LOGIN_REQUEST)
                }}
                startDecorator={<Microsoft width={16} height={16} />}
              >
                Continue with Microsoft
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: "50vw",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${backgroundImg})`
        }}
      />
    </>
  )
}
