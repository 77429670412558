import { useColorScheme, IconButton } from "@mui/joy"
import { useColorScheme as useMaterialColorScheme } from "@mui/material"
import { SunLight, HalfMoon } from "iconoir-react"

export const ModeSwitcher: React.FC = () => {
  const { setMode: setMaterialMode } = useMaterialColorScheme() // Once Joy UI reached component parity with Material UI, this can be removed
  const { mode, setMode } = useColorScheme()

  const onClickHandler = () => {
    // TODO: Once Joy UI reaches component parity with Material UI, setMaterialMode can be removed
    if (mode === "light") {
      setMode("dark")
      setMaterialMode("dark")
    } else {
      setMode("light")
      setMaterialMode("light")
    }
  }

  return (
    <IconButton
      size="sm"
      title={`switch to ${mode === "dark" ? "light" : "dark"} mode`}
      onClick={onClickHandler}
      data-testid="mode-switcher-button"
      sx={{ ":hover": { bgcolor: "transparent" } }}
    >
      {mode === "dark" ? <SunLight /> : <HalfMoon />}
    </IconButton>
  )
}
