import { GlobalStyles, IconButton, Stack } from "@mui/joy"
import { Bell, SidebarExpand } from "iconoir-react"
import React from "react"
import { EnvSelector } from "@/components/env-selector/env-selector"
import { GameSelector } from "@/components/game-selector/game-selector"
import { ModeSwitcher } from "@/components/mode-switcher/mode-switcher"
import { SeasonSelector } from "@/components/season-selector/season-selector"
import { SpaceSelector } from "@/components/space-selector/space-selector"
import { UserCard } from "@/components/user-card/user-card"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  selectIsSidebarExpanded,
  toggleNotificationCenter,
  toggleSidebar
} from "@/store/slices/app"
import { selectIsNotificationCenterOpen } from "@/store/slices/app"

export const Header: React.FC = () => {
  const dispatch = useAppDispatch()
  const isSidebarExpanded = useAppSelector(selectIsSidebarExpanded)
  const isNotificationCenterOpen = useAppSelector(
    selectIsNotificationCenterOpen
  )

  const onClickExpandSidebarHandler = () => {
    dispatch(toggleSidebar())
  }

  const onClickToggleNotificationCenter = () => {
    dispatch(toggleNotificationCenter())
  }

  return (
    <>
      <GlobalStyles
        styles={{
          ":root": {
            "--Header-height": "52px"
          }
        }}
      />
      <Stack
        component="header"
        direction="row"
        alignItems="center"
        borderBottom={1}
        borderColor="divider"
        height="var(--Header-height)"
        p={1}
        position="sticky"
        top={0}
      >
        {!isSidebarExpanded && (
          <Stack mr={1}>
            <IconButton
              size="sm"
              title="expand sidebar"
              onClick={onClickExpandSidebarHandler}
              data-testid="app-sidebar-expand"
              aria-controls="sidebar"
              aria-expanded={isSidebarExpanded}
            >
              <SidebarExpand />
            </IconButton>
          </Stack>
        )}
        <Stack direction="row" gap={1} flexGrow={1}>
          <EnvSelector />
          <Stack position="relative" sx={{ width: 260 }}>
            <GameSelector />
            <SpaceSelector />
          </Stack>
          <SeasonSelector />
        </Stack>
        <Stack direction="row" mr="5px" gap="5px">
          <ModeSwitcher />

          <IconButton
            size="sm"
            title={`${
              isNotificationCenterOpen ? "close" : "open"
            } notification center`}
            onClick={onClickToggleNotificationCenter}
            data-testid="notification-center-toggle-button"
            sx={{ ":hover": { bgcolor: "transparent" } }}
          >
            <Bell />
          </IconButton>
          <UserCard sx={{ ml: "5px" }} />
        </Stack>
      </Stack>
    </>
  )
}
