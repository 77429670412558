import { ToolboxRequest } from "@liveops-portal/lib"
import { getError } from "@/helpers/errors"
import notify from "@/helpers/notify"
import { api } from "@/store/api"

export const toolboxApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendRequest: builder.mutation<unknown, ToolboxRequest>({
      query: (requestParams) => ({
        url: "toolbox",
        body: requestParams,
        method: "post"
      }),
      onQueryStarted(requestParams, { queryFulfilled }) {
        queryFulfilled.catch((err) => {
          notify.error(
            `Something went wrong while sending the request. ${getError(err)}`
          )
        })
      }
    })
  })
})

export const { useSendRequestMutation } = toolboxApi
