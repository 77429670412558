import { ToolboxRequest } from "@liveops-portal/lib"
import { Button, List, Stack, Typography } from "@mui/joy"
import React, { useEffect, useState } from "react"
import { ToolboxHistoryItem } from "@/components/toolbox-history-item/toolbox-history-item"

interface Props {
  onClick: (index: number) => void
}

/**
 * Lists all requests done via the Toolbox.
 * @param onClick - Returns the index of the clicked history item.
 */
export const ToolboxHistory: React.FC<Props> = ({ onClick }) => {
  const [history, setHistory] = useState<ToolboxRequest[]>([])

  const localStorageToolboxHistory = localStorage.getItem("toolboxHistory")

  useEffect(
    () => setHistory(JSON.parse(localStorageToolboxHistory || "[]")),
    [localStorageToolboxHistory]
  )

  const onToolboxHistoryClearClick = () => {
    localStorage.removeItem("toolboxHistory")
    setHistory([])
  }

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography level="title-lg">Requests History</Typography>
        {history.length ? (
          <Button
            data-testid="clearHistory"
            size="sm"
            variant="outlined"
            onClick={onToolboxHistoryClearClick}
          >
            Clear history
          </Button>
        ) : null}
      </Stack>
      {history.length ? (
        <List
          sx={{
            maxHeight: "60vh",
            overflow: "scroll",
            "--ListItem-paddingX": 0
          }}
        >
          {history.map(({ method, endpoint, serviceName }, index) => (
            <ToolboxHistoryItem
              key={index}
              toolboxRequest={{ method, serviceName, endpoint }}
              onClick={() => onClick(index)}
            />
          ))}
        </List>
      ) : (
        <Typography marginTop={2} level="body-xs">
          Empty history.
        </Typography>
      )}
    </Stack>
  )
}
