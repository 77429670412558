import {
  Configuration,
  PublicClientApplication,
  RedirectRequest
} from "@azure/msal-browser"

/* Configuration constant containing the necessary infos to retrieve authentication information from Azure */
export const MSAL_INSTANCE_CONFIG: Configuration = {
  auth: {
    clientId: import.meta.env.__AZURE_CLIENT_ID_CLIENT,
    authority: `https://login.microsoftonline.com/${
      import.meta.env.__AZURE_TENANT_ID
    }`,
    redirectUri: import.meta.env.__CLIENT_URL
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
}

export const MSAL_INSTANCE = new PublicClientApplication(MSAL_INSTANCE_CONFIG)

export const MSAL_LOGIN_REQUEST: RedirectRequest = {
  redirectUri: import.meta.env.__CLIENT_URL,
  scopes: ["User.Read"]
}

export const MSAL_TOKEN_REQUEST: RedirectRequest = {
  scopes: ["User.Read"]
}

export const GRAPH_API_URL = "https://graph.microsoft.com/v1.0/me"
