import {
  BySpaceIdQuery,
  ConfigForSpaceId,
  ConfigUpdateRequest
} from "@liveops-portal/lib"
import { getError } from "@/helpers/errors"
import notify from "@/helpers/notify"
import { api } from "@/store/api"

export const configApi = api
  .enhanceEndpoints({ addTagTypes: ["Config"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getConfigBySpaceId: builder.query<ConfigForSpaceId, BySpaceIdQuery>({
        query: ({ spaceId }) => ({
          url: "configs",
          params: { spaceId }
        }),
        providesTags: ["Config"],
        onQueryStarted({ spaceId }, { queryFulfilled }) {
          queryFulfilled.catch((err) => {
            notify.error(
              `Could not get configuration for space ${spaceId}. ${getError(err)}`
            )
          })
        }
      }),
      updateConfig: builder.mutation<void, ConfigUpdateRequest>({
        query: ({ spaceId, configName, jsonData }) => ({
          url: "configs",
          method: "POST",
          body: {
            spaceId,
            configName,
            jsonData
          }
        }),
        invalidatesTags: ["Config"],
        onQueryStarted({ spaceId }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                `Configuration for ${spaceId} updated successfully.`
              )
            })
            .catch((err) => {
              notify.error(
                `Configuration for ${spaceId} could not be updated. ${getError(err)}`
              )
            })
        }
      })
    }),
    overrideExisting: false
  })

export const { useGetConfigBySpaceIdQuery, useUpdateConfigMutation } = configApi
