import { GlobalStyles, IconButton, List, Stack } from "@mui/joy"
import { SidebarCollapse } from "iconoir-react"
import React from "react"
import { Logo } from "@/components/logo/logo"
import { SidebarMenu } from "@/components/sidebar-menu/sidebar-menu"
import { NAVIGATION } from "@/constants/navigation"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { selectIsSidebarExpanded, toggleSidebar } from "@/store/slices/app"
import { selectEnv } from "@/store/slices/context"

export const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch()
  const env = useAppSelector(selectEnv)
  const isSidebarExpanded = useAppSelector(selectIsSidebarExpanded)

  return (
    <Stack
      id="app-sidebar"
      data-testid="app-sidebar"
      component="aside"
      aria-expanded={isSidebarExpanded}
      height="100dvh"
      width="var(--Sidebar-width)"
      position="fixed"
      zIndex="popup"
      top={0}
      left={0}
      borderRight={1}
      borderColor="divider"
      sx={{
        transitionDuration: "0.2s",
        transitionProperty: "left, width"
      }}
    >
      <GlobalStyles
        styles={{
          ":root": {
            "--Sidebar-width": isSidebarExpanded ? "250px" : "60px"
          }
        }}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
        height="var(--Header-height)"
        borderBottom={1}
        borderColor="divider"
      >
        <Logo to="/" collapsed={!isSidebarExpanded} />

        {isSidebarExpanded && (
          <IconButton
            size="sm"
            title="Collapse sidebar"
            onClick={() => dispatch(toggleSidebar())}
            aria-expanded={isSidebarExpanded}
            aria-controls="sidebar"
            data-testid="app-sidebar-collapse"
            sx={{ mr: -1, ":hover": { bgcolor: "transparent" } }}
          >
            <SidebarCollapse />
          </IconButton>
        )}
      </Stack>

      <Stack
        component="nav"
        sx={{
          flexGrow: 1,
          overflowX: "hidden",
          overflowY: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none"
          }
        }}
      >
        <List size="sm" sx={{ p: 0 }}>
          {NAVIGATION.map((menu) => (
            <SidebarMenu key={menu.id} {...menu} color={env || "neutral"} />
          ))}
        </List>
      </Stack>
    </Stack>
  )
}
