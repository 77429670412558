import {
  Actions,
  Battlepass,
  BattlepassDurationTypes,
  BattlepassXpRequiredTypes,
  formatTimeSpanToWeeks,
  SeasonComponentItemCreateRequest
} from "@liveops-portal/lib"
import { Button } from "@mui/joy"
import { Plus } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { BattlepassModal } from "@/components/battlepass-modal/battlepass-modal"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  useCreateSeasonComponentItemMutation,
  useGetSeasonsQuery
} from "@/store/api/season"
import { selectEnv, selectGame, selectSeason } from "@/store/slices/context"

interface FormData extends SeasonComponentItemCreateRequest<Battlepass> {
  levelsAmount: number
}

export const BattlepassCreate: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState<boolean>(false)

  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)
  const { season } = useGetSeasonsQuery(
    { gameId, env },
    {
      skip: !gameId || !env,
      selectFromResult: ({ data }) => ({
        season: data?.find((season) => season.seasonId === seasonId)
      })
    }
  )
  const { duration } = season || {}
  const seasonLength = formatTimeSpanToWeeks(duration) * 7

  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      componentType: "battlepass",
      qdOperationReason: "Battlepass creation",
      duration: {
        type: BattlepassDurationTypes.FULL,
        delay: 0,
        length: seasonLength
      },
      xpRequired: { type: BattlepassXpRequiredTypes.LINEAR },
      levelsAmount: 10,
      tracks: [
        {
          displayName: t("_seasonDesigner.battlepass.defaultTrackname")
        }
      ]
    }
  })

  const { reset: resetForm } = methods

  const [createBattlepass, mutation] = useCreateSeasonComponentItemMutation()
  const { isSuccess, isLoading } = mutation

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm])

  const onSubmitHandler = (formData: FormData) => {
    const { levelsAmount: _, ...data } = formData

    return createBattlepass({ ...data, gameId, seasonId })
  }

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [dispatch, isSuccess, onCloseHandler])

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        startDecorator={<Plus />}
        disabled={!seasonId}
      >
        {t("_seasonDesigner.battlepass.create.button")}
      </Button>

      <BattlepassModal<FormData>
        open={open}
        loading={isLoading}
        action={Actions.create}
        methods={methods}
        onSubmit={onSubmitHandler}
        onClose={onCloseHandler}
      />
    </>
  )
}
