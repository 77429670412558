import { Notification, Severity } from "@liveops-portal/lib"
import { Alert, Button, ColorPaletteProp, IconButton } from "@mui/joy"
import {
  CheckCircleSolid,
  InfoCircleSolid,
  WarningTriangleSolid,
  Xmark,
  XmarkCircleSolid
} from "iconoir-react"
import { ToastContent, ToastContentProps } from "react-toastify"

export const NotificationItem: ToastContent<Notification> = ({
  closeToast,
  data: { message, severity = Severity.Info, actions }
}: ToastContentProps<Notification>) => {
  let icon = <InfoCircleSolid data-testid="info-notification-icon" />
  let color: ColorPaletteProp = "neutral"

  switch (severity) {
    case Severity.Success:
      icon = <CheckCircleSolid data-testid="success-notification-icon" />
      color = "success"
      break
    case Severity.Error:
      icon = <XmarkCircleSolid data-testid="error-notification-icon" />
      color = "danger"
      break
    case Severity.Warning:
      icon = <WarningTriangleSolid data-testid="warning-notification-icon" />
      color = "warning"
      break
  }

  return (
    <Alert
      startDecorator={icon}
      variant="soft"
      color={color}
      endDecorator={
        <>
          {actions?.map(({ label, handler }) => (
            <Button key={label} onClick={handler} color={color}>
              {label}
            </Button>
          ))}
          <IconButton size="sm" color={color} onClick={closeToast}>
            <Xmark />
          </IconButton>
        </>
      }
    >
      {message}
    </Alert>
  )
}
