import { ReactNode, forwardRef } from "react"
import { ContextLink } from "@/components/context-link/context-link"

interface Props {
  condition: boolean
  to?: string
  children?: ReactNode
  className?: string
}

/* This component renders a link depending on a provided condition */
const ConditionalLinkComponent = (
  { children, condition, to, ...props }: Props,
  ref: React.Ref<HTMLAnchorElement>
) =>
  condition && to ? (
    <ContextLink to={to} {...props} ref={ref}>
      {children}
    </ContextLink>
  ) : (
    <a {...props} ref={ref}>
      {children}
    </a>
  )

export const ConditionalLink = forwardRef(ConditionalLinkComponent)
