import {
  Application,
  BuildSpaceAssociationsByAppId,
  Space,
  sortObjectsWithKeyword
} from "@liveops-portal/lib"
import { Stack, Typography } from "@mui/joy"
import { Unstable_Grid2 as Grid } from "@mui/material"
import React from "react"
import { ApplicationCard } from "@/components/application-card/application-card"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"

interface Props {
  applications: Application[]
  spaces: Space[] | undefined
  builds: BuildSpaceAssociationsByAppId
}

export const Applications: React.FC<Props> = ({
  applications,
  spaces,
  builds
}: Props) => {
  return applications.map(({ name, appId }) => (
    <Stack key={appId} gap={2}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography level="h3">{name}</Typography>

        <Typography level="body-xs" textColor="text.tertiary" mt={1}>
          <CopyToClipboard>{appId}</CopyToClipboard>
        </Typography>
      </Stack>
      {builds?.[appId]?.length ? (
        <Grid container spacing={2} columns={12}>
          {sortObjectsWithKeyword(builds[appId], "buildId").map(
            ({ buildId, spaceId }, i) => (
              <ApplicationCard
                key={`${buildId}-${i}`}
                buildId={buildId}
                space={spaces?.find((e) => e.spaceId === spaceId)}
              />
            )
          )}
        </Grid>
      ) : (
        <Typography>
          There are no builds associated with this application.
        </Typography>
      )}
    </Stack>
  ))
}
