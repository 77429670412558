import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom"

Sentry.init({
  dsn: import.meta.env.__SENTRY_DSN,
  integrations: [
    Sentry.httpClientIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],
  sendDefaultPii: true,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions. Sentry discourages setting this to 1.0 in production
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: import.meta.env.PROD,
  normalizeDepth: 10,
  environment: import.meta.env.__STAGE,
  initialScope: { tags: { component: "client" } }
})
