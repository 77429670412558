import { capitalize } from "@mui/material"
import { FieldError } from "react-hook-form"
import i18next from "@/i18n"

export const getError = (err: unknown) =>
  typeof err === "object" && err != null && "error" in err
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      `Reason:\n ${typeof (err as any).error === "string" ? (err as any).error : (err as any).error.message}`
    : null

/**
 * Returns default translated error messages if one is not provided
 * @param error
 * @param _label
 */
export const getFormErrorMessage = (error: FieldError, _label?: string) => {
  const { t } = i18next
  if (error.message) return error.message

  const label = _label || capitalize(t("_common.this"))

  switch (error.type) {
    case "required":
      return t("_forms.errors.required", { label })
    case "min":
      return t("_forms.errors.min", { label })
    case "max":
      return t("_forms.errors.max", { label })
    case "minLength":
      return t("_forms.errors.minLength", { label })
    case "maxLength":
      return t("_forms.errors.maxLength", { label })
  }
}
