import { HealthcheckCreateRequest } from "@liveops-portal/lib"
import { Button, Stack } from "@mui/joy"
import { isURL } from "class-validator"
import { Plus } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormSwitch } from "@/components/form-switch/form-switch"
import { Modal } from "@/components/modal/modal"
import { useCreateHealthcheckMutation } from "@/store/api/healthcheck"

interface FormData extends HealthcheckCreateRequest {}

export const HealthcheckCreate: React.FC = () => {
  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      url: "https://",
      subscriptionKey: "",
      msTeamsNotifierWebhookId: "core",
      enabled: false
    }
  })
  const [createHealthcheck, mutation] = useCreateHealthcheckMutation()
  const { isSuccess, isLoading } = mutation
  const [open, setOpen] = useState<boolean>(false)

  const onCloseHandler = useCallback(() => {
    methods.reset()
    mutation.reset()
    setOpen(false)
  }, [methods, mutation])

  const onSubmitHandler = (data: FormData) => createHealthcheck(data)

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [isSuccess, onCloseHandler])

  return (
    <>
      <Button
        variant="outlined"
        loadingPosition="start"
        onClick={() => setOpen(true)}
        startDecorator={<Plus />}
      >
        Register healthcheck
      </Button>

      <Modal
        open={open}
        onClose={onCloseHandler}
        title="Register a healthcheck"
      >
        <Form
          auditable
          methods={methods}
          loading={isLoading}
          submitLabel="Register"
          onSubmit={onSubmitHandler}
          gap={2}
          direction="column"
          fieldsToTrim={[
            "serviceName",
            "url",
            "subscriptionKey",
            "msTeamsNotifierWebhookId"
          ]}
        >
          <Stack direction="row" gap={1}>
            <FormInput
              type="text"
              name="serviceName"
              label="Service Name"
              rules={{
                required: "Service name is required",
                minLength: {
                  value: 3,
                  message: "Service name must be at least 3 characters"
                }
              }}
              sx={{ minWidth: 300 }}
            />

            <FormInput
              type="url"
              name="url"
              label="Service URL"
              rules={{
                required: "Service URL is required",
                validate: (value) =>
                  isURL(value) || "Service URL must be a valid URL"
              }}
              sx={{ flexGrow: 1 }}
            />

            <FormInput
              type="text"
              name="subscriptionKey"
              label="Subscription Key"
              rules={{ required: false }}
            />
          </Stack>

          <Stack direction="row" gap={1}>
            <FormInput
              type="text"
              name="msTeamsNotifierWebhookId"
              label="Teams Notifier Webhook ID"
              rules={{
                required: "Webhook ID is required"
              }}
              sx={{ flexGrow: 1 }}
            />
            <FormSwitch name="enabled" label="Enabled" />
          </Stack>
        </Form>
      </Modal>
    </>
  )
}
