import {
  BooleanFeatureSwitchSetRequest,
  Space,
  FeatureSwitchGetBySpaceResponse,
  BooleanFeatureSwitchDeleteRequest
} from "@liveops-portal/lib"
import { getError } from "@/helpers/errors"
import notify from "@/helpers/notify"
import { api } from "@/store/api"

export const featureSwitchApi = api
  .enhanceEndpoints({ addTagTypes: ["FeatureSwitches"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllFeatureSwitches: builder.query<
        FeatureSwitchGetBySpaceResponse,
        { spaceId: Space["spaceId"] }
      >({
        query: ({ spaceId }) => ({
          url: "/feature-switches",
          params: { spaceId }
        }),
        providesTags: ["FeatureSwitches"]
      }),
      setOverrideValue: builder.mutation<void, BooleanFeatureSwitchSetRequest>({
        query: ({ spaceId, switchName, switchValue, qdOperationReason }) => ({
          url: `/feature-switches/${switchName}/override`,
          params: { spaceId },
          body: { switchValue },
          method: "PATCH",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: ["FeatureSwitches"],
        onQueryStarted({ switchName }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(`${switchName} value successfully overridden.`)
            })
            .catch((err) => {
              notify.error(
                `${switchName}'s value could not be overridden. ${getError(err)}`
              )
            })
        }
      }),
      deleteOverrideValue: builder.mutation<
        void,
        BooleanFeatureSwitchDeleteRequest
      >({
        query: ({ spaceId, switchName, qdOperationReason }) => ({
          url: `/feature-switches/${switchName}/override`,
          params: { spaceId },
          method: "DELETE",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: ["FeatureSwitches"],
        onQueryStarted({ switchName }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(`${switchName} override successfully deleted.`)
            })
            .catch((err) => {
              notify.error(
                `${switchName}'s override could not be deleted. ${getError(err)}`
              )
            })
        }
      })
    }),
    overrideExisting: false
  })

export const {
  useGetAllFeatureSwitchesQuery,
  useSetOverrideValueMutation,
  useDeleteOverrideValueMutation
} = featureSwitchApi
