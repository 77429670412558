import {
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography,
  listItemButtonClasses,
  listItemClasses
} from "@mui/joy"

import React from "react"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useGetAllGamesQuery } from "@/store/api/game"
import { useGetAllSpacesQuery } from "@/store/api/space"
import { selectEnv, setGameId, setSpaceId } from "@/store/slices/context"

export const OverviewTree: React.FC = () => {
  const dispatch = useAppDispatch()
  const env = useAppSelector(selectEnv)
  const { data: spaces } = useGetAllSpacesQuery({ env }, { skip: !env })
  const { data: games } = useGetAllGamesQuery({ env }, { skip: !env })
  const listStyles = {
    "--ListItemDecorator-size": "28px",
    "--ListItem-minHeight": "30px"
  }
  const listItemButtonStyles = {
    [`&.${listItemButtonClasses.root}:hover`]: {
      bgcolor: "transparent",
      color: "text.primary"
    },
    [`&.${listItemButtonClasses.selected}`]: {
      bgcolor: "transparent"
    }
  }
  const chipStyles = { p: 0, minWidth: "20px", textAlign: "center" }

  const onClickHandler = (type: "game" | "space", id: string) => {
    if (type === "game") {
      dispatch(setGameId(id))
      dispatch(setSpaceId(null))
    } else {
      dispatch(setSpaceId(id))
    }
  }

  return (
    <>
      <Typography level="h3" fontSize="md" mb={1}>
        Ecosystem
      </Typography>

      <List size="sm" sx={{ ...listStyles }}>
        {games?.map(({ name, gameId }) => {
          const spacesForGame = spaces
            ?.filter((s) => s.gameId === gameId)
            .sort((a, b) => a.spaceName.localeCompare(b.spaceName))

          return (
            <ListItem nested key={gameId} sx={{ p: 0 }}>
              <ListItemButton
                data-testid="tree-item-game"
                onClick={() => {
                  onClickHandler("game", gameId)
                }}
                sx={{ ...listItemButtonStyles, py: 0 }}
              >
                <ListItemDecorator>
                  <Chip color="game" sx={{ ...chipStyles }}>
                    G
                  </Chip>
                </ListItemDecorator>
                <ListItemContent>{name}</ListItemContent>
              </ListItemButton>
              <List sx={{ ...listStyles, ml: "10px" }}>
                {spacesForGame?.map((space) => (
                  <ListItem
                    key={space.spaceId}
                    sx={{
                      p: 0,
                      position: "relative",
                      [`&.${listItemClasses.root}::before`]: {
                        content: '""',
                        borderLeft: 1,
                        borderLeftColor: "divider",
                        position: "absolute",
                        inset: 0,
                        right: "auto"
                      },
                      [`&.${listItemClasses.root}[data-last-child]::before`]: {
                        bottom: "14px"
                      }
                    }}
                  >
                    <ListItemButton
                      data-testid="tree-item-space"
                      onClick={() => {
                        onClickHandler("space", space.spaceId)
                      }}
                      sx={{
                        ...listItemButtonStyles,
                        [`&.${listItemButtonClasses.root}::before`]: {
                          content: '""',
                          borderTop: 1,
                          borderTopColor: "divider",
                          width: "20px",
                          mr: "-3px"
                        }
                      }}
                    >
                      <ListItemDecorator>
                        <Chip color="space" sx={{ ...chipStyles }}>
                          S
                        </Chip>
                      </ListItemDecorator>
                      <ListItemContent>{space.spaceName}</ListItemContent>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </ListItem>
          )
        })}
      </List>
    </>
  )
}
