import { Link, Stack, Typography, useColorScheme } from "@mui/joy"
import React from "react"
import LogoDark from "@/assets/logo-small-dark.svg?react"
import LogoLight from "@/assets/logo-small-light.svg?react"
import { ConditionalLink } from "@/components/conditional-link/conditional-link"
import { useAppSelector } from "@/hooks/store"
import { selectRevision } from "@/store/slices/app"
import { selectStage } from "../../store/slices/app"

interface Props {
  to?: string
  size?: "sm" | "md" | "lg"
  collapsed?: boolean
}

type LogoType = React.ComponentProps<"svg"> & { title?: string } & Props

export const Logo: React.FC<LogoType> = ({
  to,
  size = "md",
  collapsed = false,
  ...props
}: LogoType) => {
  const { mode } = useColorScheme()
  const stage = useAppSelector(selectStage)
  const revision = useAppSelector(selectRevision)

  /* c8 ignore start */
  let _size
  switch (size) {
    case "sm":
      _size = 26
      break
    case "md":
      _size = 32
      break
    case "lg":
      _size = 48
      break
  }
  /* c8 ignore end */

  return (
    <Link
      condition={!!to}
      to={to}
      component={ConditionalLink}
      sx={{
        ":hover": { textDecoration: "none" }
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        width={collapsed ? _size : "auto"}
        sx={{ transition: "0.2s all" }}
      >
        <Stack py="2px">
          {mode === "dark" ? (
            <LogoDark width={_size} height={_size} {...props} />
          ) : (
            <LogoLight width={_size} height={_size} {...props} />
          )}
        </Stack>
        <Stack
          ml={1}
          sx={{
            opacity: collapsed ? 0 : 1,
            pointerEvents: collapsed ? "none" : "all",
            transition: "0.2s all"
          }}
        >
          <Typography
            level="title-lg"
            sx={{
              ":hover": { color: "text.secondary" }
            }}
            noWrap
          >
            LiveOps Portal
          </Typography>
          <Typography
            level="body-xs"
            fontSize={11}
            fontWeight={400}
            lineHeight={1}
            noWrap
          >
            {stage} - {revision}
          </Typography>
        </Stack>
      </Stack>
    </Link>
  )
}
