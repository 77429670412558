import { NavItem } from "@liveops-portal/lib"
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup"
import {
  ColorPaletteProp,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  Stack,
  Typography,
  listItemButtonClasses
} from "@mui/joy"
import { Fade } from "@mui/material"
import { NavArrowDown } from "iconoir-react"
import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { ContextLink } from "@/components/context-link/context-link"
import { SidebarSubMenu } from "@/components/sidebar-submenu/sidebar-submenu"

interface Props extends NavItem {
  color: ColorPaletteProp
  expanded: boolean
}

export const SidebarItem: React.FC<Props> = ({
  id,
  name,
  icon: Icon,
  pages,
  color,
  expanded,
  disabled
}: Props) => {
  const { pathname } = useLocation()

  const to = `/${id === "overview" ? "" : id}`
  const itemSelected = pathname === to
  const subItemSelected = new RegExp(`${to}/`).test(pathname)
  const [open, setOpen] = useState<boolean>(itemSelected || subItemSelected)
  const [menuId, setMenuId] = React.useState<null | string>(null)
  const [isOnButton, setIsOnButton] = React.useState<boolean>(false)
  const [isOnMenu, setIsOnMenu] = React.useState<boolean>(false)
  const anchor = useRef<HTMLAnchorElement | null>(null)

  const {
    root: rootClass,
    selected: selectedClass,
    disabled: disabledClass
  } = listItemButtonClasses

  const onClickHandler = () => {
    expanded && setOpen(!open)
  }

  useEffect(() => {
    if (expanded) {
      setOpen(itemSelected || subItemSelected)
    } else {
      setOpen(false)
    }
  }, [expanded, itemSelected, subItemSelected])

  useEffect(() => {
    if (!isOnButton && !isOnMenu) {
      setMenuId((latestId: null | string) =>
        latestId === id ? null : latestId
      )
    }
  }, [id, isOnButton, isOnMenu])

  return (
    <ListItem
      nested
      endAction={
        expanded &&
        pages && (
          <Fade in={expanded} timeout={200}>
            <IconButton
              data-testid="expand"
              size="sm"
              sx={{
                ":hover": { bgcolor: "transparent" },
                transform: open ? "none" : "rotate(-90deg)",
                transitionProperty: "all !important"
              }}
              onClick={onClickHandler}
            >
              <NavArrowDown />
            </IconButton>
          </Fade>
        )
      }
    >
      <ListItem component="div">
        <ListItemButton
          role="button"
          selected={itemSelected}
          component={!disabled ? ContextLink : "a"}
          underline="none"
          to={!disabled ? to : undefined}
          disabled={disabled}
          onMouseEnter={(event) => {
            if (!expanded) {
              setMenuId(id)
              setIsOnButton(true)
              anchor.current = event.currentTarget
            }
          }}
          onMouseLeave={() => !expanded && setIsOnButton(false)}
          sx={({ palette }) => ({
            borderWidth: 0,
            borderLeftWidth: 2,
            pointerEvents: "auto !important",

            [`&.${rootClass}.${disabledClass}`]: {
              "--Icon-color": pages
                ? palette.text.tertiary
                : palette.neutral.plainDisabledColor
            },
            [`&.${rootClass}.${disabledClass}:hover`]: {
              bgcolor: "transparent",
              color: palette.neutral.plainDisabledColor
            },
            [`&.${rootClass}.${selectedClass}`]: {
              "--Icon-color": palette[color].softColor,
              borderLeftColor: palette[color].solidBg,
              bgcolor: palette[color].softBg
            },
            [`&.${rootClass}.${selectedClass}.${disabledClass}:hover`]: {
              bgcolor: palette[color].softBg
            }
          })}
        >
          <ListItemDecorator onClick={onClickHandler}>
            <Icon />
          </ListItemDecorator>
          <Fade in={expanded}>
            <Typography
              fontSize="sm"
              noWrap
              sx={{
                color:
                  disabled && !pages
                    ? "neutral.plainDisabledColor"
                    : "text.secondary"
              }}
            >
              {name}
            </Typography>
          </Fade>
        </ListItemButton>
      </ListItem>

      {expanded ? (
        pages && (
          <SidebarSubMenu
            pages={pages}
            open={open}
            parent={id}
            color={color}
            expanded={expanded}
          />
        )
      ) : (
        <BasePopup
          role={pages ? "menu" : "tooltip"}
          open={menuId === id}
          anchor={anchor.current}
          placement="right-start"
          /* Testing library cannot mimic cursor transition */
          /* c8 ignore start */
          onMouseEnter={() => setIsOnMenu(true)}
          onMouseLeave={() => setIsOnMenu(false)}
          /* c8 ignore end */
          style={{ zIndex: 1000 }}
        >
          <Stack pl={1} justifyContent="center" minHeight={40}>
            {pages ? (
              <SidebarSubMenu
                pages={pages}
                open={menuId === id}
                parent={id}
                color={color}
                expanded={expanded}
              />
            ) : (
              <Typography
                fontSize="sm"
                variant="outlined"
                px={1.5}
                py={0.5}
                borderRadius="sm"
                marginInline={0}
                bgcolor="background.body"
                textColor={
                  disabled ? "neutral.plainDisabledColor" : "text.secondary"
                }
              >
                {name}
              </Typography>
            )}
          </Stack>
        </BasePopup>
      )}
    </ListItem>
  )
}
