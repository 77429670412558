import {
  AutocompleteOption,
  Autocomplete,
  createFilterOptions,
  ListItemContent,
  Typography,
  Chip
} from "@mui/joy"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useGetAllGamesQuery } from "@/store/api/game"
import { useGetSeasonsQuery } from "@/store/api/season"
import {
  selectContext,
  selectEnv,
  selectGame,
  selectSeason,
  setSeasonId
} from "@/store/slices/context"

export const SeasonSelector: React.FC = () => {
  const dispatch = useAppDispatch()
  const env = useAppSelector(selectEnv)
  const context = useAppSelector(selectContext)
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)
  const seasonInit = useRef(false)
  const [searchParams] = useSearchParams()
  const seasonParam = useMemo(
    () => searchParams.get("seasonId"),
    [searchParams]
  )

  const { data: games, isFetching: isFetchingGames } = useGetAllGamesQuery(
    { env },
    { skip: !env }
  )

  const { data: seasons, isFetching: isFetchingSeasons } = useGetSeasonsQuery(
    { gameId, env },
    { skip: !gameId || !env }
  )

  const isFetching = isFetchingGames || isFetchingSeasons

  const initializeSeason = useCallback(() => {
    seasonParam != null && dispatch(setSeasonId(seasonParam))
  }, [dispatch, seasonParam])

  useEffect(() => {
    if (!seasonInit.current) {
      initializeSeason()
      seasonInit.current = true
    }
  }, [initializeSeason])

  const value = useMemo(
    () => seasons?.find((season) => season.seasonId === seasonId) || null,
    [seasonId, seasons]
  )

  return (
    games &&
    seasons && (
      <Autocomplete
        autoComplete
        id="season-selector"
        placeholder={"Select a season"}
        disabled={isFetching}
        value={value}
        options={seasons}
        filterOptions={createFilterOptions({
          stringify: (option) => `${option?.seasonName} ${option?.seasonId}`
        })}
        onChange={(_, _value) =>
          dispatch(setSeasonId(_value?.seasonId || null))
        }
        sx={{
          opacity: Number(context.includes("season")),
          width: 260,
          pointerEvents: context.includes("season") ? "all" : "none"
        }}
        startDecorator={seasonId ? <Chip>Season</Chip> : null}
        isOptionEqualToValue={(option, value) =>
          option.seasonId === value.seasonId
        }
        getOptionLabel={(option) => option.seasonName}
        renderOption={(props, option) => (
          <AutocompleteOption {...props} key={option.seasonId}>
            <ListItemContent>
              <Typography level="body-sm" textColor="text.primary">
                {option.seasonName}
              </Typography>
              <Typography level="body-xs" fontWeight="400" noWrap>
                {option.seasonId}
              </Typography>
            </ListItemContent>
          </AutocompleteOption>
        )}
      />
    )
  )
}
