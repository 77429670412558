import { FormLabel, Select, Option } from "@mui/joy"
import { SyntheticEvent } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  onChange: (_: SyntheticEvent | null, value: number | null) => void
}

export const AuditDurationPicker = ({ onChange }: Props) => {
  const { t } = useTranslation()

  return (
    <FormLabel>
      {t("_auditLogs.duration")}
      <Select
        size="sm"
        name="duration"
        defaultValue={15}
        onChange={onChange}
        sx={{ ml: 1 }}
      >
        {[15, 30, 60, 90].map((value) => (
          <Option key={value} value={value}>
            {value.toString()}
          </Option>
        ))}
      </Select>
    </FormLabel>
  )
}
