import { InteractionStatus, InteractionType } from "@azure/msal-browser"
import {
  AuthenticatedTemplate,
  useMsal,
  useMsalAuthentication
} from "@azure/msal-react"
import { Environments, NavPage } from "@liveops-portal/lib"
import { Sheet, Stack, Typography } from "@mui/joy"
import * as Sentry from "@sentry/react"
import React, { useEffect, useMemo } from "react"
import { Helmet } from "react-helmet"
import { Trans } from "react-i18next"
import { Slide, ToastContainer } from "react-toastify"
import { ContextLink } from "@/components/context-link/context-link"
import { Header } from "@/components/header/header"
import { NotificationCenter } from "@/components/notification-center/notification-center"
import { Page } from "@/components/page/page"
import { Sidebar } from "@/components/sidebar/sidebar"
import { MSAL_LOGIN_REQUEST } from "@/constants/auth"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { LoginPage } from "@/pages/login-page/login-page"
import { selectRevision, selectStage } from "@/store/slices/app"
import { selectActiveOperation } from "@/store/slices/audit"
import { selectContext, setContext } from "@/store/slices/context"

interface Props extends NavPage {}

export const Layout: React.FC<Props> = ({
  context,
  name,
  title,
  element
}: Props) => {
  useMsalAuthentication(InteractionType.Silent, MSAL_LOGIN_REQUEST)
  const { accounts, inProgress } = useMsal()

  const dispatch = useAppDispatch()
  const activeOperationReason = useAppSelector(selectActiveOperation)
  const stage = useAppSelector(selectStage)
  const revision = useAppSelector(selectRevision)
  const _context = useAppSelector(selectContext)
  const showWarning = stage === Environments.ONLINEDEV && revision !== "local"
  const Component = element

  useEffect(() => {
    if (JSON.stringify(_context) !== JSON.stringify(context))
      dispatch(setContext(context))
  }, [dispatch, context, _context])

  const pageTitle = useMemo(() => title || name, [name, title])

  useEffect(() => {
    if (accounts[0]) Sentry.setUser({ email: accounts[0].username })
  }, [accounts])

  return (
    <>
      <Helmet defaultTitle="LiveOps Portal" titleTemplate="%s - LiveOps Portal">
        <title>{pageTitle}</title>
        <meta
          property="iframely:title"
          content={`LiveOps Portal > ${pageTitle}`}
        />
      </Helmet>
      {inProgress === InteractionStatus.None && (
        <>
          <AuthenticatedTemplate>
            <Sidebar />
            <Stack
              component="section"
              height="100dvh"
              ml="var(--Sidebar-width)"
              position="relative"
              sx={{ transition: "margin 0.2s" }}
            >
              <Header />
              {showWarning && (
                <Sheet color="warning" variant="solid" invertedColors>
                  <Typography textAlign="center" fontSize="xs" p={0.5}>
                    <Trans
                      i18nKey="_app.stageWarning"
                      components={[
                        <ContextLink to={import.meta.env.__LIVE_URL} />
                      ]}
                    />
                  </Typography>
                </Sheet>
              )}
              <Stack
                component="main"
                sx={{
                  transition: "all 0.2s",
                  overflow: "auto",
                  p: 2,
                  position: "absolute",
                  inset: 0,
                  top: showWarning
                    ? "calc(var(--Header-height) + 26px)"
                    : "var(--Header-height)",
                  /* c8 ignore start */
                  ...(activeOperationReason && {
                    bottom: "var(--OperationReason-height)"
                  })
                  /* c8 ignore end */
                }}
              >
                <Page title={title ? title : name}>
                  {Component ? (
                    <Component />
                  ) : (
                    <>
                      This page has not been implemented yet. Come back later.
                    </>
                  )}
                </Page>

                <ToastContainer
                  role="none"
                  limit={3}
                  autoClose={3000}
                  transition={Slide}
                  icon={false}
                  closeButton={false}
                  hideProgressBar={true}
                />
                <NotificationCenter />
              </Stack>
              <Stack
                id="operation-reason"
                sx={{
                  position: "absolute",
                  inset: 0,
                  top: "auto"
                }}
              />
            </Stack>
          </AuthenticatedTemplate>
          {!accounts.length && <LoginPage />}
        </>
      )}
    </>
  )
}
