import {
  ConfigForSpaceId,
  formatJsonToString,
  sortStringsWithKeyword,
  isValidJson
} from "@liveops-portal/lib"
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Typography
} from "@mui/joy"
import React, { useEffect, useMemo, useState } from "react"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { SelectWithAdd } from "@/components/select-with-add/select-with-add"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  useGetConfigBySpaceIdQuery,
  useUpdateConfigMutation
} from "@/store/api/config"
import { setActiveOperation, resetOperation } from "@/store/slices/audit"
import { selectSpace } from "@/store/slices/context"

/**
 * Component representing the config page.
 * If a space is selected, displays the configuration viewer.
 * If no space is selected, prompts the user to choose a space.
 */
export const ConfigPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const spaceId = useAppSelector(selectSpace)
  const [categories, setCategories] = useState<string[]>([])
  const [activeCategory, setActiveCategory] = useState<string | null>("general")
  const [initialConfig, setInitialConfig] = useState<ConfigForSpaceId>({})
  const [publicConfig, setPublicConfig] = useState<string>("{}")
  const [privateConfig, setPrivateConfig] = useState<string>("{}")
  const {
    data: config,
    isSuccess,
    isUninitialized,
    isFetching
  } = useGetConfigBySpaceIdQuery({ spaceId }, { skip: !spaceId })
  const [updateConfig, { isLoading }] = useUpdateConfigMutation()

  const isConfigEdited = useMemo(
    () =>
      config &&
      (formatJsonToString(initialConfig.public) !== publicConfig ||
        formatJsonToString(initialConfig.private) !== privateConfig),
    [config, initialConfig, privateConfig, publicConfig]
  )

  useEffect(() => {
    if (isConfigEdited && isSuccess) {
      dispatch(setActiveOperation(["config", "edit"]))
    } else {
      dispatch(resetOperation())
    }
  }, [dispatch, isConfigEdited, isSuccess])

  useEffect(() => {
    if (!config) return

    const _initialConfig =
      (activeCategory && (config[activeCategory] as ConfigForSpaceId)) || {}

    setCategories(sortStringsWithKeyword(Object.keys(config), "general"))
    setInitialConfig(_initialConfig)
    setPublicConfig(formatJsonToString(_initialConfig.public))
    setPrivateConfig(formatJsonToString(_initialConfig.private))
  }, [activeCategory, config])

  const onSubmitHandler = () => {
    const updatedConfig = {
      private: JSON.parse(privateConfig),
      public: JSON.parse(publicConfig)
    }

    updateConfig({
      spaceId,
      configName: activeCategory,
      jsonData: updatedConfig
    })
  }

  return isUninitialized || !spaceId ? (
    <Typography>
      Please choose a space to view the associated configuration.
    </Typography>
  ) : isFetching ? (
    <Typography>Loading...</Typography>
  ) : config && Object.keys(config).length ? (
    <Stack gap={2} component="form" flexGrow={1}>
      <OperationReason
        open={!!isConfigEdited || isLoading}
        disabled={
          !!publicConfig &&
          !!privateConfig &&
          (!isValidJson(publicConfig) || !isValidJson(privateConfig))
        }
        onSubmit={onSubmitHandler}
      />

      <FormControl>
        <FormLabel>Configuration category</FormLabel>
        <SelectWithAdd
          name="configName"
          type="category"
          options={categories}
          value={activeCategory}
          setValue={setActiveCategory}
          onInputChange={(event) => {
            const value = event.target.value
            const formattedValue = value.replace(/\s/g, "-")
            event.target.value = formattedValue
          }}
          sx={{ width: 300 }}
        />
        {activeCategory === "general" && (
          <FormHelperText>
            The general configuration of a space is read-only.
          </FormHelperText>
        )}
      </FormControl>

      <Stack flexGrow={1} gap={1} direction="row" position="relative">
        <CodeEditor
          editable={activeCategory !== "general"}
          name="_public"
          value={publicConfig}
          label="Public configuration"
          onChange={setPublicConfig}
          error={!isValidJson(publicConfig)}
          sx={{ flexBasis: "50%" }}
        />
        <CodeEditor
          editable={activeCategory !== "general"}
          name="_private"
          value={privateConfig}
          label="Private configuration"
          onChange={setPrivateConfig}
          error={!isValidJson(privateConfig)}
          sx={{ flexBasis: "50%" }}
        />
      </Stack>
    </Stack>
  ) : (
    <Typography>No config found for selected space.</Typography>
  )
}
