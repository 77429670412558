import { useEffect, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import { DEFAULT_GAMEID } from "@/constants/context"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useGetAllSpacesQuery } from "@/store/api/space"
import {
  selectEnv,
  selectGame,
  selectSeason,
  selectSpace,
  setGameId,
  setSeasonId,
  setSpaceId
} from "@/store/slices/context"

export const useUpdateContextParams = () => {
  const dispatch = useAppDispatch()
  const [, setSearchParams] = useSearchParams()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const seasonId = useAppSelector(selectSeason)
  const switchGame = useRef(true)

  const { data: spaces } = useGetAllSpacesQuery({ env }, { skip: !env })

  useEffect(() => {
    setSearchParams((params) => {
      const previous = params.get("env")
      if (previous !== env && env !== null) {
        params.set("env", env)
        dispatch(setGameId(DEFAULT_GAMEID))
      }
      return params
    })
  }, [dispatch, env, setSearchParams])

  useEffect(() => {
    setSearchParams((params) => {
      const previous = params.get("gameId")
      gameId !== null && params.set("gameId", gameId)

      if (previous !== gameId && !switchGame.current) {
        dispatch(setSpaceId(null))
        dispatch(setSeasonId(null))
      }
      switchGame.current = false
      return params
    })
  }, [dispatch, gameId, setSearchParams])

  useEffect(() => {
    if (!spaces?.length) return

    setSearchParams((params) => {
      if (spaceId === null) {
        params.delete("spaceId")
        return params
      }

      const previousGame = params.get("gameId")
      const nextGame = spaces.find((s) => spaceId === s.spaceId)?.gameId
      if (nextGame && previousGame !== nextGame) {
        switchGame.current = true
        dispatch(setGameId(nextGame))
      }

      params.set("spaceId", spaceId)
      return params
    })
  }, [spaceId, setSearchParams, spaces, dispatch])

  useEffect(() => {
    setSearchParams((params) => {
      if (seasonId === null) {
        params.delete("seasonId")
        return params
      }

      params.set("seasonId", seasonId)
      return params
    })
  }, [seasonId, setSearchParams, dispatch])
}
