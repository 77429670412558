export enum DataState {
  WAITING_FOR_UPLOAD = "Waiting for upload",
  READY_FOR_USE = "Ready for use",
  IN_USE = "In use"
}

export const Actions: Record<string, string> = {
  update: "update",
  create: "create",
  delete: "delete",
  publish: "publish"
} as const

export type Action = (typeof Actions)[keyof typeof Actions]
